import * as i from 'types';
import * as React from 'react';
import Helmet from 'react-helmet';
import { useTransform, useMotionTemplate } from 'framer-motion';

import theme from 'styles/theme';
import { useChangeHeaderColor, useScrollDistanceForElementInView } from 'services/hooks';

import {
  IntroductionEyesContainer,
  ExtendedVideo,
  HeaderMotionContainer,
  HeaderContainer,
} from './styled';

import { externalAssets } from 'components/common/externalAssets';

export const IntroductionEyes: React.FC<IntroductionEyesProps> = ({ bannerImage }) => {
  const [elementRef] = useChangeHeaderColor({
    background: 'transparent',
    text: theme.colors.white,
  });

  const [containerRef, scrolledDistance] = useScrollDistanceForElementInView();

  const headerPositionY = useTransform(
    scrolledDistance, [0.5, 1], [-100, 200]);
  const templateHeaderPositionY = useMotionTemplate`translateY(${headerPositionY}px)`;

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href={bannerImage?.file?.url ?
            bannerImage?.file.url
            : externalAssets.videos.nowatchWeb
          }
          as="video"
        />
      </Helmet>
      <HeaderContainer ref={containerRef}>
        <IntroductionEyesContainer ref={elementRef}>
          <HeaderMotionContainer style={{ transform: templateHeaderPositionY }}>
            <ExtendedVideo
              playsInline
              muted
              autoPlay
              preload="auto"
              loop
              src={bannerImage?.file.url ? bannerImage?.file.url : externalAssets.videos.nowatchWeb}
            />
          </HeaderMotionContainer>
        </IntroductionEyesContainer>
      </HeaderContainer>
    </>
  );
};

type IntroductionEyesProps = Partial<Pick<i.BlogPageHeaderProps, 'bannerImage'>>;
