import styled from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const FirstHeadingContainer = styled.div`
  padding: 5vh 5%;
  position: relative;
`;

export const ExtendedText = styled(Text)`
  font-size: 8vw;
  line-height: 100%;

  ${media.tablet`
    font-size: 5vw;
  `}

  ${media.desktop`
    font-size: 3vw;
  `}
`;


export const WatchFilmText = styled(Text)`
  font-size: 25px;
  padding: 150px 0 100px;
  text-align: center;
  opacity: 0.6;
`;
