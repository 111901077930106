import styled from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const IntroductionButtonSideviewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  margin-top: 15%;

  ${media.desktop`
    flex-direction: row-reverse;
  `}
`;

export const IntroductionButtonSideviewTitleContainer = styled.div`
  padding: 0px 6vw;
  margin-bottom: 13%;

  ${media.tablet`
    padding: 0 10vw;
  `}

  ${media.desktop`
    padding: 0 5vw;
    margin-bottom: 0;
  `}
`;

export const ExtendedTextDescription = styled(Text)`
  font-size: 4vw;
  max-width: 90%;

  ${media.tablet`
    font-size: 3vw;
  `}

  ${media.desktop`
    font-size: 1.6vw;
    max-width: 90%;
  `}
`;

export const ExtendedCanvas = styled.canvas`
    pointer-events: none;
    width: 100vw;
    align-self: flex-end;

  ${media.desktop`
    flex-shrink: 0;
    width: 55vw;
  `}
`;
