import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Heading } from 'common/typography';

import { media } from 'styles/utils';

export const CustomisationCollectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 15vw;
`;

export const ExtendedMotionInterchangabilityTitleWrapper = styled(motion.div)`
  display: flex;
  position: sticky;
  z-index: 2;
  top: 45vh;
  justify-content: center;
  padding: 0 5%;
`;

export const CustomisationCollectionBackgroundImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 20vw;

  .collection {
    width: 100%;
    pointer-events: none;
    user-select: none;
  }

  .collectionDesktop {
    display: none;
  }

  ${media.desktop`
    padding-top: 10vw;
    padding-bottom: 20vw;

    .collectionDesktop {
      display: block;
    }

    .collectionMobile {
      display: none;
    }
  `}
`;

export const ExtendedVideo = styled.video`
  width: 100%;

  ${media.desktop`
    width: 60vw;
  `}
`;

export const CustomisationCollectionVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  pointer-events: none;
  user-select: none;
`;

export const ExtendedHeading = styled(Heading)`
  ${media.desktop`
    text-align: center;
  `}
`;
