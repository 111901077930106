import React from 'react';

import DailyMailIcon from 'vectors/logos/daily_mail.svg';
import TheGuardianIcon from 'vectors/logos/the_guardian.svg';
import WiredIcon from 'vectors/logos/wired.svg';

import {
  DailyMailTextWrapper,
  DailyMailWrapper,
  DesktopDivider,
  ExtendedText,
  IntroductionPressContainer,
  IntroductionPressLogos,
  IntroductionPressText,
  TheGuardianTextWrapper,
  TheGuardianWrapper,
  WiredTextWrapper,
  WiredWrapper,
} from './styled';

export const IntroductionPress: React.FC = () => {
  return (
    <IntroductionPressContainer>
      <TheGuardianWrapper>
        <TheGuardianIcon />
      </TheGuardianWrapper>
      <WiredWrapper>
        <WiredIcon />
      </WiredWrapper>
      <DailyMailWrapper>
        <DailyMailIcon />
      </DailyMailWrapper>
      <DesktopDivider />
      <TheGuardianTextWrapper>
        <ExtendedText>
          "The stress-forecasting timepiece"
        </ExtendedText>
      </TheGuardianTextWrapper>
      <WiredTextWrapper>
        <ExtendedText>
          "There is no time like the present with NOWATCH"
        </ExtendedText>
      </WiredTextWrapper>
      <DailyMailTextWrapper>
        <ExtendedText>
          "New high-tech device can predict when you&apos;re about to become too stressed"
        </ExtendedText>
      </DailyMailTextWrapper>
    </IntroductionPressContainer>
  );
};
