import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';
import { Heading, Text } from 'common/typography';

export const CustomisationStonesAccordionItem = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  overflow: hidden;
  transition: .2s ease-in-out;

  :hover {
    background: ${({ theme }) => theme.colors.sand.lighter};
  }
`;

export const CustomisationStonesAccordionContainer = styled.div`
  display: flex;
  margin-top: 15%;
  flex-direction: column;

  ${CustomisationStonesAccordionItem}:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  }

  ${media.desktop`
    margin-top: 5%;
  `}
`;

export const CustomisationStonesAccordionItemHeader = styled.div`
  height: 25vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;

  ${media.desktop`
    height: 8vw;
    transition: .2s ease-in-out;
    overflow: hidden;

    .gatsby-image-wrapper {
      transition: .2s ease-in-out;
    }
    :hover {
      margin: 0 0 0 20px;

        .gatsby-image-wrapper {
        transform: scale(1.05);
        }
      }
    }
  `}
`;

export const CustomisationStonesAccordionItemExtendedHeading = styled(Heading)`
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  font-size: 6vw;
  margin: 0;
  padding: 0 7vw;

  ${media.desktop`
    padding: 0 3vw;
    font-size: 4vw;
  `}
`;

export const CustomisationStonesAccordionItemImageContainer = styled.div`
  width: 100%;
  max-width: 25vw;
  height: 25vw;
  overflow: hidden;

  .material {
    width: 100%;
    user-select: none;
    pointer-events: none;
  }

  ${media.desktop`
    max-width: 45vw;
    height: 8vw;
  `}
`;

export const CustomisationStonesAccordionExtendedMotionDivItemContent = styled(motion.div)`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
  `}
`;

export const CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer = styled.div`
  ${media.desktop`
    width: 50%;
    flex-shrink: 0;
  `}
`;

export const CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer = styled.div`
  margin: 5vw;

  ${media.desktop`
    width: 50%;

    .variations {
      width: 100%;
    }
  `}
`;

export const CustomisationStonesAccordionItemContentDescription = styled(Text)`
  font-size: 4vw;
  line-height: 5.5vw;
  padding: 10vw 7vw;
  margin: 0;

  ${media.tablet`
    font-size: 3vw;
    line-height: 5vw;
  `}

  ${media.desktop`
    font-size: 1.6vw;
    line-height: 2.5vw;
    padding: 4vw 3vw;
  `}

  ${media.large`
    font-size: 1.4vw;
    line-height: 2vw;
  `}
`;

export const CustomisationStonesAccordionItemContentChemicalCompound = styled(CustomisationStonesAccordionItemContentDescription)`
  font-weight: bold;
  padding: 10vw 7vw 0;

  ${media.desktop`
    padding: 4vw 3vw 0;
  `}
`;
