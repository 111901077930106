import * as React from 'react';
import { useTransform } from 'framer-motion';

import {
  useScrollDistanceForElementInView,
  useDevice,
  usePreloader,
} from 'services/hooks';

// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../../../../services/webworkers/loader.worker.ts';

import { SensorsSensorList } from '../SensorsSensorList';
import { AnimationBase } from './animationBase';

import {
  SensorsExplodedViewContainer,
  SensorsExplodedViewWrapper,
  ExtendedCanvas,
} from './styled';

export const SensorsExplodedView: React.FC<SensorsExplodedViewProps> = ({ isAlwaysMobile }) => {
  const { addAssetToPreload, addLoadedAsset } = usePreloader();
  const [elementRef, scrolledDistance] = useScrollDistanceForElementInView();
  // Use useState and not useRef, because the latter doesn't trigger the
  // useMemo below when the ref is set.
  const [canvasRef, setCanvasRef] = React.useState<HTMLCanvasElement | null>(null);
  const frameTime = useTransform(scrolledDistance, [0.2, 0.75], [0, 1]);
  const { isMobile, isTablet, determined } = useDevice();

  const [worker, setWorker] = React.useState<Worker>();

  React.useEffect(() => {
    const loaded = new Worker();
    setWorker(loaded);

    return () => worker?.terminate();
  }, []);

  const animation: AnimationBase | null = React.useMemo(() => {
    const canvas = canvasRef;

    if (!canvas || !worker) return null;

    const platform = (isMobile || isTablet) ? 'mobile' : 'desktop';

    return new AnimationBase(canvas, platform, addAssetToPreload, addLoadedAsset, worker);
  }, [canvasRef, worker]);

  React.useEffect(() => {
    if (!animation) return;

    if (isMobile || isTablet || isAlwaysMobile) {
      animation.changeDevice('mobile');
    } else {
      animation.changeDevice('desktop');
    }
  }, [isMobile, isTablet]);

  React.useEffect(() => {
    if (!animation || !determined) return;

    // Start preloading images
    animation.preloadImages();

    // Listen to the scrolledDistance onchange and not the frameTime onChange to
    // prevent the first/last frame from not being drawn/issues with overwritten
    // frames once images are done loading. This makes it easier to immediatly
    // have the right frame drawn and has no performance setbacks.
    // scrolledDistance is updated immediatly after page load, frameTime will
    // only update if the value of scrolledDistance actually changes within its
    // input range.
    const unsubscribe = scrolledDistance.onChange(() => {
      // Use requestAnimation frame to change the frame before the next repaint
      window.requestAnimationFrame(() => animation.update(frameTime.get()));
    });

    // Make sure a frame is also being drawn on load. Otherwise a frame is only
    // being drawn after you scroll a little bit because a new
    // scrolledDistance.onChange is not triggered after a hot module reload/page load.
    window.requestAnimationFrame(() => animation.update(frameTime.get()));

    return () => unsubscribe();
  }, [animation, determined]);

  return (
    <SensorsExplodedViewContainer ref={elementRef}>
      <SensorsExplodedViewWrapper >
        <ExtendedCanvas
          ref={setCanvasRef}
        />
      </SensorsExplodedViewWrapper>
      <SensorsSensorList
        scrolledDistance={scrolledDistance}
      />
    </SensorsExplodedViewContainer>
  );
};

type SensorsExplodedViewProps = {
  isAlwaysMobile?: boolean;
};

