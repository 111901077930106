import * as React from 'react';
import { useSpring, useTransform, MotionValue, useMotionTemplate } from 'framer-motion';

import { CardContainer } from './styled';

export const DesktopWatch: React.FC<ParallaxCardProps> = ({ scrolledDistance, transformValues, image }) => {
  const headerPositionY = useTransform(
    scrolledDistance,
    [0, 0.5],
    (transformValues ? transformValues : [100, -700]),
  );
  const y = useSpring(headerPositionY, { stiffness: 450, damping: 100 });
  const templateHeaderPositionY = useMotionTemplate`translateX(${y}px)`;

  return (
    <CardContainer style={{ transform: templateHeaderPositionY }}>
      <img src={image} alt="NOWATCH Atelier introduction desktop" />
    </CardContainer>
  );
};

type ParallaxCardProps = {
  scrolledDistance: MotionValue<number>;
  transformValues?: number[];
  image: string;
}
