import * as React from 'react';
import { useTransform, MotionValue, useMotionTemplate } from 'framer-motion';

import { CardContainer } from './styled';

export const ParallaxCard: React.FC<ParallaxCardProps> = ({ scrolledDistance, transformValues, images }) => {
  const headerPositionY = useTransform(
    scrolledDistance,
    [0, 0.8],
    (transformValues ? transformValues : [100, -300]),
  );
  const templateHeaderPositionY = useMotionTemplate`translateY(${headerPositionY}px)`;

  return (
    <CardContainer style={{ transform: templateHeaderPositionY }}>
      {images && images.map((image, index) =>
        (<img key={index} src={image} alt="some screen" />),
      )}
    </CardContainer>
  );
};

type ParallaxCardProps = {
  scrolledDistance: MotionValue<number>;
  transformValues?: number[];
  images: string[];
}
