import * as React from 'react';
import { useTransform, useMotionTemplate, motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import { Heading } from 'common/typography';
import { useScrollDistanceForElementInView, useDevice } from 'services/hooks';
import { easeOutSine } from 'services/animationHelpers';

import {
  CustomisationInterchangabilityContainer,
  ExtendedMotionInterchangabilityTitleWrapper,
  ExtendedTextInterchangabilityDescription,
  ExtendedMotionInterchangabilityDescriptionWrapper,
  CustomisationInterchangabilityWatchContainer,
  ExtendedMotionCustomisationInterchangabilityStonesContainer,
  CustomisationInterchangabilityStonesWrapper,
  CustomisationInterchangabilityOverflowWrapper,
  ExtendedStaticHeading,
  ExtendedText,
} from './styled';

export const CustomisationInterchangability: React.FC = () => {
  const [elementRef, scrolledDistance] = useScrollDistanceForElementInView();
  const { isMobile, isTablet } = useDevice();

  const yTitleTextContainer = useTransform(scrolledDistance, [0.05, 0.15], [2, 0], {
    ease: easeOutSine,
  });
  const transformYTitleTextContainer = useMotionTemplate`translateY(${yTitleTextContainer}vw)`;

  return (
    <CustomisationInterchangabilityContainer ref={elementRef}>
      <ExtendedMotionInterchangabilityTitleWrapper
        style={{
          transform: transformYTitleTextContainer,
        }}
      >
        <ExtendedStaticHeading as="h2" variant="display" $uppercase>
          Inter&shy;changeable elements
        </ExtendedStaticHeading>
        <ExtendedText $isCentered $lineHeight={170}>
          No two journeys towards a more meaningful life look the same. Choose between beautifully machined stainless steel discs and ancient natural gemstones, and switch faces easily with the magnetic interchanger.
        </ExtendedText>
      </ExtendedMotionInterchangabilityTitleWrapper>
      <CustomisationInterchangabilityOverflowWrapper>
        <ExtendedMotionCustomisationInterchangabilityStonesContainer
          animate={{
            x: isMobile || isTablet ? (
              ['-49vw', '-49vw', '-181vw', '-181vw', '-49vw']
            ) : (
              ['-1vw', '-1vw', '-68.9vw', '-68.9vw', '-1vw']
            ),
          }}
          transition={{
            duration: 10,
            ease: 'easeInOut',
            times: [0, 0.45, 0.5, 0.95, 1],
            repeat: Infinity,
            repeatType: 'loop',
            repeatDelay: 1,
          }}
        >
          <CustomisationInterchangabilityStonesWrapper>
            <StaticImage
              src="../../../../assets/images/interchangability_stone_6.png"
              alt="Interchangability Stone 1"
              placeholder="blurred"
              layout="fullWidth"
              quality={50}
              className="stone"
            />
            <motion.div
              animate={{
                y: isMobile || isTablet ? (
                  ['0vw', '29.5vw', '29.5vw', '0vw', '0vw']
                ) : (
                  ['0vw', '13.2vw', '13.2vw', '0vw', '0vw']
                ),
              }}
              transition={{
                duration: 10,
                ease: 'easeInOut',
                times: [0, 0.1, 0.2, 0.3, 1],
                repeat: Infinity,
                repeatType: 'loop',
                repeatDelay: 1,
              }}
            >
              <StaticImage
                src="../../../../assets/images/interchangability_stone_2.png"
                alt="Interchangability Stone 2"
                placeholder="blurred"
                layout="fullWidth"
                quality={50}
                className="stone"
              />
            </motion.div>
            <StaticImage
              src="../../../../assets/images/interchangability_stone_3.png"
              alt="Interchangability Stone 3"
              placeholder="blurred"
              layout="fullWidth"
              quality={50}
              className="stone"
            />
            <motion.div
              animate={{
                y: isMobile || isTablet ? (
                  ['0vw', '0vw', '29.5vw', '29.5vw', '0vw', '0vw']
                ) : (
                  ['0vw', '0vw', '13.2vw', '13.2vw', '0vw', '0vw']
                ),
              }}
              transition={{
                duration: 10,
                ease: 'easeInOut',
                times: [0, 0.6, 0.7, 0.8, 0.9, 1],
                repeat: Infinity,
                repeatType: 'loop',
                repeatDelay: 1,
              }}
            >
              <StaticImage
                src="../../../../assets/images/interchangability_stone_4.png"
                alt="Interchangability Stone 4"
                placeholder="blurred"
                layout="fullWidth"
                quality={50}
                className="stone"
              />
            </motion.div>
            <StaticImage
              src="../../../../assets/images/interchangability_stone_5.png"
              alt="Interchangability Stone 5"
              placeholder="blurred"
              layout="fullWidth"
              quality={50}
              className="stone"
            />
          </CustomisationInterchangabilityStonesWrapper>
        </ExtendedMotionCustomisationInterchangabilityStonesContainer>
        <CustomisationInterchangabilityWatchContainer>
          <StaticImage
            src="../../../../assets/images/interchangability_watch_case_no_pins.png"
            alt="Interchangability Watch Case"
            placeholder="blurred"
            layout="fullWidth"
            quality={50}
            className="watch_case"
            style={{ position: 'absolute', overflow: 'hidden' }}
          />
          <StaticImage
            src="../../../../assets/images/interchangability_watch_overlay.png"
            alt="Interchangability Watch Overlay"
            placeholder="blurred"
            layout="fullWidth"
            quality={50}
            className="watch_overlay"
            style={{ position: 'absolute', overflow: 'hidden' }}
          />
          <StaticImage
            src="../../../../assets/images/interchangability_watch.png"
            alt="Interchangability Watch Strap"
            placeholder="blurred"
            layout="fullWidth"
            quality={50}
            className="watch"
          />
        </CustomisationInterchangabilityWatchContainer>
      </CustomisationInterchangabilityOverflowWrapper>
    </CustomisationInterchangabilityContainer>
  );
};
