import styled from 'styled-components';
import { motion } from 'framer-motion';

export const BlogPageHeaderWrapper = styled.div`
  padding: 0 0 20px 0;
`;

export const StickyBar = styled(motion.div)`
  position: fixed;
  bottom: 0;
  transition: .5s ease;
  background: ${({ theme }) => theme.colors.sand.lighter} 50%;
  width: 100%;
  left: 0;
  height: 60px;
  display: flex;
  z-index: 20;
  justify-content: space-between;
  align-items: center;
  padding: 0 21px
`;
