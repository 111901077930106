import * as React from 'react';

import { ContentBlocksContainer } from 'modules/contentBlocks';
import { IntroductionFocusSentences } from 'modules/introduction';
import { CustomisationInterchangability } from 'modules/customisation';
import { HomeProduct } from 'modules/homeContentBlocks';

export const CampaignInterchangeBlock: React.FC = () => {
  return (
    <ContentBlocksContainer>
      <IntroductionFocusSentences
        isCentered
        sentences={[
          'A new relationship with technology.',
          'A new relationship with yourself.',
        ]}
      />
      <CustomisationInterchangability />
      <HomeProduct />
    </ContentBlocksContainer>
  );
};
