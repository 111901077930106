import styled from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const IntroductionHapticWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15%;
  overflow: hidden;

  ${media.desktop`
    min-height: 30vh;
    flex-direction: row-reverse;
    margin-bottom: 10%;
  `}
`;

export const ExtendedTextHapticTitle = styled(Text)`
  font-size: 7vw;
  line-height: 100%;
  padding: 0 2vw;
  text-align: center;

  span {
    opacity: 0.7;
  }

  ${media.tablet`
    font-size: 4vw;
    padding: 0 10%;
  `}

  ${media.desktop`
    padding: 0px 14% 0 3%;
    font-size: 3vw;
    text-align: left;
  `}
`;

export const LottieContainer = styled.div`
  pointer-events: none;
  width: 100vw;

  ${media.desktop`
    flex-shrink: 0;
    width: 40vw;
    margin-right: 5%;
  `}
`;
