import styled from 'styled-components';

import { Button } from 'common/interaction';
import { StaticText } from 'common/typography/Static';

export const ExtendedButton = styled(Button)`
  height: auto;
  padding: 14px 24px;
  width: fit-content;
`;

export const ExtendedText = styled(StaticText)`
  font-size: 12px;
  margin: 4px 0 0;
`;

export const MetaWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100%;

  .gatsby-image-wrapper {
    height: 48px;
    width: 40px;
    object-fit: contain;
    display: block;
    margin: 0 16px 0 0;
  };
`;
