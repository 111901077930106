import styled from 'styled-components';

import { StaticHeading, Text } from 'common/typography';
import { media } from 'styles/utils';

export const HomeDesignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10vh 10vw 0;
  gap: 32px;
  background-color: ${({ theme }) => theme.colors.sand.lighter};
  margin: 0 0 -20px 0;
  z-index: -1;

  ${media.desktop`
    gap: 140px;
    padding: 140px 0 140px;
  `}

  ${media.large`
    gap: 140px;
    padding: 140px 0 140px;
  `}
`;

export const HomeDesignHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const ExtendedStaticHeading = styled(StaticHeading)`
  margin: 0;
  text-align: center;

  ${media.desktop`
    font-size: 6.5vw;
  `}
`;

export const ExtendedText = styled(Text)`
  max-width: 800px;
`;
