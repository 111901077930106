import {
  HomeAtelier,
  HomePhilosophy,
  HomePageBlogPosts,
  HomeApp,
} from 'modules/homeContentBlocks';
import {
  CampaignPressBlock,
  CampaignInterchangeBlock,
  CampaignMembershipBlock,
  CampaignTechnologyBlock,
  CampaignCollectionBlock,
} from 'modules/campaign';
import { BlogPageBody } from 'modules/blog';

export const ComponentList = {
  ContentfulComponentAppBlock: HomeApp,
  ContentfulComponentAtelierBlock: HomeAtelier,
  ContentfulComponentBlogBlock: HomePageBlogPosts,
  ContentfulComponentBodyText: BlogPageBody,
  ContentfulComponentInterchangeBlock: CampaignInterchangeBlock,
  ContentfulComponentCollectionBlock: CampaignCollectionBlock,
  ContentfulComponentTechnologyBlock: CampaignTechnologyBlock,
  ContentfulComponentPressBlock: CampaignPressBlock,
  ContentfulComponentPhilosophyBlock: HomePhilosophy,
  // placeholder till we the newsletterblock is built
  ContentfulComponentNewsletterBlock: HomePhilosophy,
  ContentfulComponentMembershipBlock: CampaignMembershipBlock,
};
