import * as i from 'types';
import React from 'react';
import { useTransform, useMotionTemplate } from 'framer-motion';

import { IntroductionEyes } from 'modules/introduction';
import { FirstHeading } from 'modules/homeContentBlocks';
import { ContentBlocksContainer } from 'modules/contentBlocks';
import { BlogPageHeader } from 'modules/blog';
import { CampaignStickyHeaderBar } from 'modules/campaign';

import { useScrollDistanceForElementInView } from 'services/hooks';

import { BlogPageHeaderWrapper, StickyBar } from './styled';

export const CampaignHeader: React.FC<i.BlogPageHeaderProps> = ({
  title,
  subtitle,
  bannerImage,
  ctaLink,
  $alignImageRight,
  $hasRoundedCorners,
  nowatchImage,
  nameOfWatch,
  price,
}) => {
  const [elementRef, scrolledDistance] = useScrollDistanceForElementInView();
  const headerPositionY = useTransform(
    scrolledDistance, [0.7, 0.8], [100, 0]);
  const templateHeaderPositionY = useMotionTemplate`translateY(${headerPositionY}px`;

  if (bannerImage.file.contentType === 'video/mp4') {
    return (
      <>
        <IntroductionEyes
          bannerImage={bannerImage}
        />
        <ContentBlocksContainer
          ref={elementRef}
        >
          <FirstHeading
            title={title}
            subtitle={subtitle}
            ctaLink={ctaLink}
          />
        </ContentBlocksContainer>
        {ctaLink &&
          <StickyBar
            style={{ transform: templateHeaderPositionY }}
          >
            <CampaignStickyHeaderBar
              ctaLink={ctaLink}
              nowatchImage={nowatchImage}
              nameOfWatch={nameOfWatch}
              price={price}
            />
          </StickyBar>
        }
      </>
    );
  }

  return (
    <>
      <BlogPageHeaderWrapper
        ref={elementRef}
      >
        <BlogPageHeader
          title={title}
          subtitle={subtitle}
          bannerImage={bannerImage}
          $alignImageRight={$alignImageRight}
          ctaLink={ctaLink}
          $hasRoundedCorners={$hasRoundedCorners}
          hideMetaAndBackButton
        />
      </BlogPageHeaderWrapper>
      {ctaLink &&
        <StickyBar
          style={{ transform: templateHeaderPositionY }}
        >
          <CampaignStickyHeaderBar
            ctaLink={ctaLink}
            nowatchImage={nowatchImage}
            nameOfWatch={nameOfWatch}
            price={price}
          />
        </StickyBar>
      }
    </>
  );
};
