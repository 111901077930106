import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

export const SensorsSensorListContainer = styled.div`
  padding: 0 5vw;
  margin-bottom: 10vh;

  ${media.tablet`
    padding: 0 10vw;
  `}

  ${media.desktop`
    padding: 0 5vw;
  `}

  ${media.large`
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
  `}
`;

export const ExtendedMotionDiv = styled(motion.div)`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const SensorsSensorListSectionItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 6vw;
  font-weight: 100;
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  color: ${({ theme }) => theme.colors.codGray};
  text-transform: uppercase;
  align-items: center;
  margin-top: 4vw;

  ${media.tablet`
    font-size: 5vw;
  `}

  ${media.desktop`
    margin-top: 2vw;
    font-size: 2vw;
  `}
`;

export const IconContainer = styled.div`
  width: 11vw;
  height: 11vw;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.codGray};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10vw;
  flex-shrink: 0;

  .hrv {
    width: 35%;
    height: 35%;
  }

  .cortisol {
    width: 45%;
    height: 45%;
  }

  .spo2 {
    width: 30%;
    height: 30%;
  }

  .temperature {
    width: 50%;
    height: 50%;
  }

  .barometer {
    width: 55%;
    height: 55%;
  }

  .breathrate {
    width: 40%;
    height: 40%;
  }

  ${media.tablet`
    width: 6vw;
    height: 6vw;
    margin-right: 5vw;
  `}

  ${media.desktop`
    width: 3vw;
    height: 3vw;
    margin-right: 6vw;

    .hrv {
      width: 45%;
      height: 45%;
    }

    .cortisol {
      width: 55%;
      height: 55%;
    }

    .spo2 {
      width: 40%;
      height: 40%;
    }

    .temperature {
      width: 60%;
      height: 60%;
    }

    .barometer {
      width: 65%;
      height: 65%;
    }

    .breathrate {
      width: 50%;
      height: 50%;
    }
  `}

  ${media.large`
    margin-right: 4vw;
  `}
`;

export const TextWrapper = styled.span`
  width: 100%;

  ${media.desktop`
    text-align: right;
  `}
`;

export const BadgeContainer = styled.div`
  position: relative;
  top: -0.65em;
  height: 30px;
  margin-bottom: -30px;

  ${media.desktop`
    top: -0.6em;
  `}
`;

export const BadgeTextWrapper = styled.span`
  padding: 2px 8px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.codGray};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.4em;
`;

export const SensorsSensorListSection = styled.div<SensorsSensorListSectionProps>`
  display: flex;
  flex-direction: column;

  ${({ reversed }) => reversed && css`
    ${media.desktop`
      ${SensorsSensorListSectionItem} {
        flex-flow: row-reverse;
      }

      ${IconContainer} {
        margin-right: 0;
        margin-left: 6vw;
      }

      ${TextWrapper} {
        text-align: left;
      }
    `}

    ${media.large`
      ${IconContainer} {
        margin-left: 4vw;
      }
    `}
  `}
`;

type SensorsSensorListSectionProps = {
  reversed?: boolean;
};
