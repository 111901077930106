import styled from 'styled-components';
import { Heading } from 'common/typography';

import { media } from 'styles/utils';

export const ExtendedHeading = styled(Heading)`
  ${media.desktop`
    text-align: center;
  `}
`;
