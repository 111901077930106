import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { PreloaderProvider } from 'services/context/preloaderContext';
import { Template, FloatingReadMore } from 'common/layout';
import { CampaignHeader } from 'modules/campaign/CampaignHeader';
import { ContentArea } from 'common/contentArea';

const campaignDetailPage: React.FC<CampaignDetailPageProps> = ({ data }) => {
  const campaign = data.contentfulCampaign;
  const body = campaign.body;
  const {
    title,
    subtitle,
    bannerImage,
    ctaLink,
    $alignImageRight,
    hasRoundedCorners,
    nameOfWatch,
    nowatchImage,
    price,
  } = campaign;

  return (
    <PreloaderProvider>
      <Template>
        <CampaignHeader
          bannerImage={bannerImage}
          subtitle={subtitle}
          title={title}
          ctaLink={ctaLink}
          $hasRoundedCorners={hasRoundedCorners}
          $alignImageRight={$alignImageRight}
          nameOfWatch={nameOfWatch}
          nowatchImage={nowatchImage}
          price={price}
        />
        <FloatingReadMore alignRight />
        <ContentArea contentTypes={body} />
      </Template>
    </PreloaderProvider>
  );
};

export default campaignDetailPage;

type CampaignDetailPageProps = {
  data: i.CampaignDetailType;
};

export const query = graphql`
  query Campaign($id: String) {
    contentfulCampaign(id: {eq: $id}) {
      id
      title
      subtitle
      slug
      alignImageRight
      hasRoundedCorners
      ctaLink
      price
      nameOfWatch
      nowatchImage {
        gatsbyImageData
      }
      body {
        ...CampaignDetailPageFragment
      }
      bannerImage {
        gatsbyImageData
        id
        file {
          contentType
          url
        }
      }
    }
  }
`;

export const campaignDetailPageFragment = graphql`
  fragment CampaignDetailPageFragment on Node {
    ... on ContentfulComponentBodyText {
      __typename
      content {
        references {
          ...BlogPageBodyFragment
        }
        raw
      }
    }
    ... on ContentfulComponentAppBlock {
      __typename
    }
    ... on ContentfulComponentAtelierBlock {
      __typename
    }
    ... on ContentfulComponentBlogBlock {
      __typename
    }
    ... on ContentfulComponentInterchangeBlock {
      __typename
    }
    ... on ContentfulComponentCollectionBlock {
      __typename
    }
    ... on ContentfulComponentTechnologyBlock {
      __typename
    }
    ... on ContentfulComponentPressBlock {
      __typename
    }
    ... on ContentfulComponentPhilosophyBlock {
      __typename
    }
    ... on ContentfulComponentNewsletterBlock {
      __typename
    }
    ... on ContentfulComponentMembershipBlock {
      __typename
    }
  }
`;
