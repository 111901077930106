import * as React from 'react';
import { useTransform, useMotionTemplate, motion } from 'framer-motion';

import { easeOutSine } from 'services/animationHelpers';
import { useScrollDistanceForElementInView } from 'services/hooks';

import {
  IntroductionFocusSentencesWrapper,
  ExtendedTextFocusSentence,
} from './styled';

export const IntroductionFocusSentences: React.FC<IntroductionFocusSentencesProps> = ({ sentences, isCentered }) => {
  const [elementRef, scrolledDistance] = useScrollDistanceForElementInView();

  const yContainer = useTransform(scrolledDistance, [0.1, 0.3], [10, 0], {
    ease: easeOutSine,
  });
  const transformYContainer = useMotionTemplate`translateY(${yContainer}vh)`;

  return (
    <IntroductionFocusSentencesWrapper
      ref={elementRef}
    >
      <motion.div
        style={{
          transform: transformYContainer,
        }}
      >
        {sentences.map((sentence) => (
          <ExtendedTextFocusSentence
            $isCentered={isCentered}
            $margin="0"
            key={sentence}
          >
            {sentence}
          </ExtendedTextFocusSentence>
        ))}
      </motion.div>
    </IntroductionFocusSentencesWrapper>
  );
};

type IntroductionFocusSentencesProps = {
  sentences: string[];
  isCentered?: boolean;
};
