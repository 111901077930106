import styled from 'styled-components';

import { media } from 'styles/utils';
import { TextAndImageBlockExtraFieldsImage } from 'modules/contentBlocks';

export const HomeTextAndImageBlockImage = styled(TextAndImageBlockExtraFieldsImage)`
  .home-text-and-image-block_image {
    border-radius: 20px;
    user-select: none;
    pointer-events: none;
    margin-bottom: 6vw;

    ${media.desktop`
      width: 30vw;
      height: 25vw;
      margin-bottom: 0;
    `}

    img {
      border-radius: 20px;
    }
  }
`;
