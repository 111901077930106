import styled from 'styled-components';

import { media } from 'styles/utils';

export const IntroductionWatchesAnimationContainer = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${media.large`
    margin-bottom: 5%;
  `}
`;

export const IntroductionWatchesAnimationWrapper = styled.div`
  display: flex;
  height: 200vh;
  justify-content: center;

  ${media.large`
    height: 175vh;
  `}
`;

export const ExtendedCanvas = styled.canvas`
  pointer-events: none;
  height: calc(100vh - 46px);
  position: sticky;
  top: 46px;
  object-fit: cover;
  max-width: 100%;

  ${media.desktop`
    height: calc(100vh - 62px);
    top: 62px;
  `}
`;
