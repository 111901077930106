import * as React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';

import { StaticButtonText, StaticText } from 'common/typography/Static';

import { ExtendedButton, ExtendedText, MetaWrapper } from './styled';

export const CampaignStickyHeaderBar: React.FC<CampaignStickyHeaderBarProps> = ({ ctaLink, price, nowatchImage, nameOfWatch }) => {
  return (
    <>
      <MetaWrapper>
        {nowatchImage &&
          <GatsbyImage
            image={getImage(nowatchImage)!}
            alt="thumbnail"
            objectFit="cover"
          />
        }
        <div>
          <StaticText $uppercase $margin="0">{nameOfWatch}</StaticText>
          <ExtendedText>€{price}</ExtendedText>
        </div>
      </MetaWrapper>
      {ctaLink &&
        <ExtendedButton
          to={ctaLink}
          variant="primary"
          small
          type="button"
        >
          <StaticButtonText>
            Shop now
          </StaticButtonText>
        </ExtendedButton>
      }
    </>
  );
};

type CampaignStickyHeaderBarProps = {
  ctaLink: string;
  price?: string;
  nowatchImage?: {
    IGatsbyImageData: IGatsbyImageData,
    file: {
      contentType?: string;
      url: string
    };
  };
  nameOfWatch?: string;
};
