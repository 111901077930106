import styled from 'styled-components';

import { media } from 'styles/utils';
import { StaticHeading, Text } from 'common/typography';
import { HomeTextAndImageBlockImage } from 'modules/homeContentBlocks/HomeTextAndImageBlock';

export const HomePhilosophyTopContainer = styled.div`
  padding: 0 5%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 32px;
`;

export const ExtendedText = styled(Text)`
  max-width: 800px;
`;

export const HomePhilosophyContainer = styled.div`
  width: 100%;
  padding: 10vh 0 0;
  margin: auto;

  ${media.desktop`
    gap: 120px;
    padding: 100px 0 0;
    max-width: 75vw;
  `}

  ${media.large`
    gap: 140px;
    padding: 140px 0 0;
  `}
`;

export const ExtendedStaticHeading = styled(StaticHeading)`
  margin: 0;
  text-align: center;

  ${media.desktop`
    font-size: 6.5vw;
  `}
`;

export const ExtendedHomeTextAndImageBlockImage = styled(HomeTextAndImageBlockImage)`
  .home-text-and-image-block_image {
    ${media.desktop`
      height: auto;
    `}
  }
`;
