import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { gtagTrackAndOpen } from 'services/trackingHelpers';
import {
  useDevice,
  useScrollDistanceForElementInView,
} from 'services/hooks';
import { StaticButtonText } from 'common/typography';
import mid from 'images/atelier_intro_Middle.png';
import leftFacingWatch1 from 'images/atelier_intro-left_1.png';
import leftFacingWatch2 from 'images/atelier_intro-left_2.png';
import leftFacingWatch3 from 'images/atelier_intro-left_3.png';
import rightFacingWatch1 from 'images/atelier_intro-right_1.png';
import rightFacingWatch2 from 'images/atelier_intro-right_2.png';
import rightFacingWatch3 from 'images/atelier_intro-right_3.png';

import { HomeAtelierDemoSteps } from '../HomeAtelierDemoSteps';
import { DesktopWatch } from './components/DesktopWatch';
import {
  ExtendedStaticHeading,
  ExtendedText,
  ExtendedButton,
  HomeAtelierContainer,
  HomeAtelierHeaderWrapper,
  HomeAtelierFooterWrapper,
  HomeAtelierStaticImageContainer,
  MiddleWatch,
} from './styled';

export const HomeAtelier: React.FC = () => {
  const { isMobile } = useDevice();

  const [heightRef, scrolledDistance] = useScrollDistanceForElementInView();
  const watchesLeft = [
    { image: leftFacingWatch3, transformValues: [-180, 240] },
    { image: leftFacingWatch2, transformValues: [-150, 120] },
    { image: leftFacingWatch1, transformValues: [-100, 0] },
  ];
  const watchesRight = [
    { image: rightFacingWatch1, transformValues: [100, 0] },
    { image: rightFacingWatch2, transformValues: [150, -120] },
    { image: rightFacingWatch3, transformValues: [180, -240] },
  ];

  return (
    <div ref={heightRef}>
      <HomeAtelierContainer>
        <HomeAtelierHeaderWrapper>
          <ExtendedStaticHeading as="h2" variant="display" $uppercase>
            Create your NOWATCH
          </ExtendedStaticHeading>
          <ExtendedText $isCentered $lineHeight={170}>
            Where does your Awareable take you when you glance at it?<br /> Into a quiet reflective space, or to a mysterious far away destination?<br /> It’s up to you.
          </ExtendedText>
          <ExtendedText $isCentered $lineHeight={170}>
              Discover ancient natural gemstones and modern minimalist discs, along with wrist straps and hardware to suit your most meaningful life.
          </ExtendedText>
        </HomeAtelierHeaderWrapper>
        <HomeAtelierStaticImageContainer>
          {isMobile ? (
            <StaticImage
              src="../../../../assets/images/atelier-mobile-2.png"
              alt="NOWATCH Atelier introduction image mobile"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
            />
          ) : (
            <>
              {watchesLeft.map((watch, index) =>
                (<DesktopWatch
                  key={index}
                  image={watch.image}
                  transformValues={watch.transformValues}
                  scrolledDistance={scrolledDistance}
                />),
              )}
              <MiddleWatch src={mid} alt="Emerald NOWATCH" />
              {watchesRight.map((watch, index) =>
                (<DesktopWatch
                  key={index}
                  image={watch.image}
                  transformValues={watch.transformValues}
                  scrolledDistance={scrolledDistance}
                />),
              )}
            </>
          )}
        </HomeAtelierStaticImageContainer>
        <HomeAtelierFooterWrapper>
          {!isMobile && <HomeAtelierDemoSteps />}
          <ExtendedButton
            variant="primary"
            href="https://shop.nowatch.com?utm_medium=referral&utm_source=nowatch.com&utm_content=product-page-button-atelier-banner"
            onClick={(event) => gtagTrackAndOpen({
              event,
              url: 'https://shop.nowatch.com?utm_medium=referral&utm_source=nowatch.com&utm_content=product-page-button-atelier-banner',
              eventName: 'click_atelier_banner',
              eventCategory: 'ecommerce',
              eventLabel: window.location.pathname,
            })}
          >
            <StaticButtonText>
              Go to Atelier
            </StaticButtonText>
          </ExtendedButton>
        </HomeAtelierFooterWrapper>
      </HomeAtelierContainer>
    </div>
  );
};
