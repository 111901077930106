import styled from 'styled-components';

import { media } from 'styles/utils';
import {
  HeaderTitle,
} from 'modules/contentBlocks';

export const ExtendedHeaderTitle = styled(HeaderTitle)`
  transform: none;
  position: relative;
  left: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16vw;

  ${media.desktop`
    font-size: 8vw;
  `}
`;

export const ProductHeaderContentBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 0 20px;
  position: absolute;
  bottom: 5%;

  ${media.desktop`
    bottom: auto;
    padding: 0 50px;
    height: 100%;
    gap: 30px;
  `}
`;
