import styled from 'styled-components';

import { media } from 'styles/utils';
import { HeaderWithVideoVideo } from 'modules/contentBlocks';
import { StaticHeading, Text } from 'common/typography';
import { Button } from 'common/interaction';

export const HomePhilosophyTopContainer = styled.div`
  padding: 0 5%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 32px;
`;

export const ExtendedVideo = styled(HeaderWithVideoVideo)`
  height: 100vh;
  max-height: none;
`;

export const ExtendedButton = styled(Button)`
  height: auto;
  padding: 14px 24px;
`;

export const ExtendedText = styled(Text)`
  max-width: 1200px;
`;

export const HomePhilosophyContainer = styled.div`
  width: 100%;
  padding: 10vh 0 0;
  background-color: ${({ theme }) => theme.colors.sand.lighter};
  height: 200vh;

  ${media.desktop`
    gap: 120px;
    padding: 100px 0 0;
  `}

  ${media.large`
    gap: 140px;
    padding: 140px 0 0;
  `}
`;

export const HomePhilosophyTextContainer = styled.div`
  width: 100%;
  padding: 10vh 0 0;
  background-color: ${({ theme }) => theme.colors.sand.lighter};
  margin: 0 0 -20px 0;
  z-index: -1;

  ${media.desktop`
    gap: 120px;
    padding: 100px 0 100px;
  `}

  ${media.large`
    gap: 140px;
    padding: 140px 0 140px;
  `}
`;

export const Container = styled.div`
  position: sticky;
  top: 0;
`;

export const ExtendedStaticHeading = styled(StaticHeading)`
  margin: 0;
  text-align: center;

  ${media.desktop`
    font-size: 6.5vw;
  `}
`;
