import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const ExtendedTextFocusSentence = styled(Text)<ExtendedTextFocusSentenceProps>`
  font-size: 8vw;
  line-height: 100%;
  text-transform: uppercase;

  ${({ $isCentered }) => $isCentered && css`
    text-align: center;
  `}

  ${media.tablet`
    font-size: 5vw;
  `}

  ${media.desktop`
    font-size: 3vw;
  `}
`;

type ExtendedTextFocusSentenceProps = {
  $isCentered?: boolean;
}

export const IntroductionFocusSentencesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
  margin-top: 8vh;
  margin-bottom: 160px;

  ${ExtendedTextFocusSentence}:not(:first-child) {
    margin-top: 3%;
    opacity: 0.65;
  }

  ${ExtendedTextFocusSentence}:nth-child(3) {
    opacity: 0.50;
  }

  ${ExtendedTextFocusSentence}:first-child {
    opacity: 1;
  }

  ${media.tablet`
    padding: 0 50px;
  `}

  ${media.desktop`
    margin-top: 10%;

    ${ExtendedTextFocusSentence}:not(:first-child) {
      margin-top: 0.5%;
    }
  `}
`;
