import * as React from 'react';

import { ContentBlocksContainer } from 'modules/contentBlocks';
import { CustomisationStonesAccordion } from 'modules/customisation';

import { ExtendedHeading } from './styled';

export const CampaignCollectionBlock: React.FC = () => {
  return (
    <ContentBlocksContainer>
      <ExtendedHeading margin="120px 0">
          The<br />coll&shy;ection
      </ExtendedHeading>
      <CustomisationStonesAccordion />
    </ContentBlocksContainer>
  );
};
