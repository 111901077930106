import * as React from 'react';

import theme from 'styles/theme';
import { Text, StaticButtonText } from 'common/typography';
import { Button } from 'common/interaction';
import { useChangeHeaderColor } from 'services/hooks';

import { FirstHeadingContainer, ExtendedText, WatchFilmText } from './styled';

export const FirstHeading: React.FC<FirstHeadingProps> =
({
  title,
  subtitle,
  ctaLink,
}) => {
  const [elementRef] = useChangeHeaderColor({
    background: theme.colors.springWood,
    text: theme.colors.black,
  });

  return (
    <FirstHeadingContainer ref={elementRef}>
      {title ? (
        <ExtendedText>
          {title}
        </ExtendedText>
      ) : (
        <ExtendedText>
          TIME IS NOW.<br />Don’t miss a moment.
        </ExtendedText>
      )}
      <Text>
        {subtitle ? subtitle : 'Meet the world’s first Awareable. Inspiring you to live in the moment. By quietly capturing your most important bio-data and giving tailored feedback. So you can restore balance and wellbeing, in this moment and every moment for a life more fully lived.'}
      </Text>
      <WatchFilmText>Watch the film<br />&#8964;</WatchFilmText>
      {ctaLink && (
        <Button
          to={ctaLink}
          variant="primary"
          size="auto"
          type="button"
        >
          <StaticButtonText>
            Shop now
          </StaticButtonText>
        </Button>
      )}
    </FirstHeadingContainer>
  );
};

type FirstHeadingProps = {
  title?: string;
  subtitle?: string;
  ctaLink?: string;
};
