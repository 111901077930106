import styled from 'styled-components';
import { motion } from 'framer-motion';

export const IntroductionEyesContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ExtendedVideo = styled.video`
  width: 100%;
  height: 50vh;
  display: block;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
`;

export const HeaderMotionContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  position: relative;
  z-index: 0;
  margin-bottom: -20px;
  z-index: -1;
`;
