export const externalAssets = {
  videos: {
    nowVisual: 'https://brand.nowatch.com/m/14b5738f989b348c/original/nowvisual.mp4',
    collectionMP4: 'https://brand.nowatch.com/m/258e69f119c53305/original/collection.mov',
    collectionWebM: 'https://brand.nowatch.com/m/258e69f119c53305/original/collection.webm',
    brandFilmMobile: 'https://brand.nowatch.com/m/2242ee64d3bf36bc/original/nowatch_brand_film_mobile.mp4',
    brandFilmDesktop: 'https://brand.nowatch.com/m/77b198fc0b5d9f35/original/nowatch_brand_film_desktop.mp4',
    nowatchWeb: 'https://brand.nowatch.com/m/59efae2f4c0e2803/original/v1-nowatch-web.mp4',
    rotationLeft: 'https://brand.nowatch.com/m/370b951106b66b9e/original/rotation_left.mp4',
    rotationRight: 'https://brand.nowatch.com/m/3986f244b4e711ac/original/rotation_right.mp4',
    introWatchesMobile: 'https://brand.nowatch.com/m/611c62791875de64/original/introduction_watches_mobile.mp4',
    introWatchesDesktop: 'https://brand.nowatch.com/m/1125af7ef50f337e/original/introduction_watches_desktop.mp4',
  }
};
