import * as i from 'types';
import * as React from 'react';

import { ComponentList } from './componentList';

export const ContentArea: React.FC<ContentAreaProps> = ({ contentTypes }) => {
  if (contentTypes == null) return null;

  return (
    <>
      {
        contentTypes.map((item) => {
          const DynamicComponent = ComponentList[item?.__typename];

          if (!DynamicComponent) return null;
          //@ts-ignore
          return <DynamicComponent key={item.id} content={item} />;
        })
      }
    </>
  );
};

type ContentAreaProps = {
  contentTypes: i.ContentAreaBlocksTypes[];
};
