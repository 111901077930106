import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';
import { StaticHeading, Text } from 'common/typography';

export const CustomisationInterchangabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 15vw;
`;

export const ExtendedMotionInterchangabilityTitleWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10vw;
  padding: 0 5%;
  gap: 24px;

  ${media.desktop`
    margin-bottom: 1vw;
  `}
`;

export const ExtendedTextInterchangabilityDescription = styled(Text)`
  font-size: 4vw;
  max-width: 90%;

  ${media.tablet`
    font-size: 3vw;
  `}

  ${media.desktop`
    font-size: 1.2vw;
    max-width: 40%;
    line-height: 150%;
    margin-bottom: 4vw;
    text-align: center;
    margin: 0 auto;
  `}
`;

export const ExtendedMotionInterchangabilityDescriptionWrapper = styled(motion.div)`
  display: flex;
  padding: 0 5%;
  margin-bottom: 15vw;

  ${media.desktop`
    margin-bottom: 5vw;
  `}
`;

export const CustomisationInterchangabilityWatchContainer = styled.div`
  width: 90vw;
  position: absolute;
  left: 5vw;

  .watch_overlay,
  .watch_case,
  .watch {
    position: absolute;
    z-index: 3;
    width: 90vw;
  }

  .watch_overlay {
    z-index: 4;
  }

  .watch_case {
    z-index: 2;
  }

  ${media.desktop`
    width: 50vw;
    left: 25vw;

    .watch_overlay, .watch_case, .watch {
      width: 50vw;
    }
  `}
`;

export const ExtendedMotionCustomisationInterchangabilityStonesContainer = styled(motion.div)`
  position: absolute;
  z-index: 3;
  top: 5vw;

  ${media.desktop`
    top: 0;
  `}
`;

export const CustomisationInterchangabilityStonesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .stone {
    margin: 0 -12vw;
    width: 90vw;
    flex-shrink: 0;
  }

  ${media.desktop`
    .stone {
      margin: 0 -8.5vw;
      width: 51vw;
    }
  `}
`;

export const CustomisationInterchangabilityOverflowWrapper = styled.div`
  overflow: hidden;
  padding-top: 35vw;
  position: relative;
  height: 115vw;

  ${media.desktop`
    padding-top: 14vw;
    height: 60vw;
  `}
`;

export const ExtendedStaticHeading = styled(StaticHeading)`
  margin: 0;
  text-align: center;

  ${media.desktop`
    font-size: 6.5vw;
  `}
`;

export const ExtendedText = styled(Text)`
  max-width: 800px;
`;
