import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

export const CardContainer = styled(motion.div)`
  height: 100%;
  width: 100%;

  img {
    height: 250px;

    ${media.large`
    height: 300px;
  `}
  };
`;
