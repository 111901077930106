import styled from 'styled-components';

import { Heading } from 'common/typography';
import { media } from 'styles/utils';
import { Button } from 'common/interaction';

export const IntroductionAtelierContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  padding: 0 32px;
  margin: 200px auto 0;
  gap: 32px;

  ${media.desktop`
    gap: 80px;
  `}
`;

export const IntroductionAtelierText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  ${media.desktop`
    flex-direction: row;
    gap: 48px;
  `}
`;

export const AtelierHeading = styled(Heading)`
  font-size: 60px;
  line-height: 60px;
  margin: 0;

  ${media.desktop`
    font-size: 144px;
    line-height: 144px;
  `}
`;

export const IntroductionAtelierButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  ${media.desktop`
    align-items: flex-start;
  `}
`;

export const ExtendedButton = styled(Button)`
  text-transform: none;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;
