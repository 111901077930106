import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { StaticText } from 'common/typography';

export const HomeAtelierStepNowatchNavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
`;

export const HomeAtelierStepNavigationWrapper = styled.div<AtelierStepNavigationWrapperProps>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 16px;
  cursor: default;
  transition: opacity 0.2s ease-in-out;

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    opacity: 0.5;

    > button {
      cursor: not-allowed;
    }
  `}

  ${media.desktop`
    gap: 8px;
  `}
`;

type AtelierStepNavigationWrapperProps = {
  disabled?: boolean;
};

export const ExtendedStaticText = styled(StaticText)`
  align-items: center;
  display: flex;
  padding: 0;
  margin: 0;
  max-width: 800px;
  line-height: 0;
`;

export const HorizontalRule = styled.hr`
  width: 8px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.sand.dark};
  border: 0;
`;

export const ExtendedNumber = styled.div`
  display: none;

  ${media.desktop`
    background-color: ${({ theme }) => theme.colors.text};
    border-radius: 100%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: ${({ theme }) => theme.colors.sand.light};
    }
  `}
`;
