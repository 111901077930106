import * as i from 'types';
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { StaticButtonText } from 'common/typography';
import { BlogInterestingArticle } from 'modules/blog';

import theme from 'styles/theme';
import { useChangeHeaderColor } from 'services/hooks';
import {
  InterestingArticlesContainer,
  ArticlesListWrapper,
  ExtendedButton,
  ExtendedStaticHeading,
  ExtendedText,
  HomeBlogHeaderWrapper,
  HomeBlogPostsContainer,
} from './styled';

export const HomePageBlogPosts: React.FC = () => {
  const [elementRef] = useChangeHeaderColor({
    background: theme.colors.sand.lighter,
    text: theme.colors.black,
  });

  const data = useStaticQuery<GatsbyTypes.HomeBlogArticlesQuery>(query);
  const articles = data.allContentfulArticleWeb.nodes as unknown as i.BlogArticleType[];

  return (
    <HomeBlogPostsContainer ref={elementRef}>
      <HomeBlogHeaderWrapper>
        <ExtendedStaticHeading as="h2" variant="display" $uppercase>
          In the NOW<br />
          News & Community
        </ExtendedStaticHeading>
        <ExtendedText $isCentered $lineHeight={170}>
          From inspiring ambassadors to the latest learnings on topics like sleep, stress, movement and mental wellbeing, In The Now is our news and community space for the curious.
        </ExtendedText>
        <ExtendedButton
          variant="primary"
          to="/"
        >
          <StaticButtonText>
            Go to In the NOW
          </StaticButtonText>
        </ExtendedButton>
      </HomeBlogHeaderWrapper>
      <InterestingArticlesContainer>
        <ArticlesListWrapper>
          {articles?.map((article) => (
            <BlogInterestingArticle
              article={article}
              key={article.id}
            />
          ))}
        </ArticlesListWrapper>
      </InterestingArticlesContainer>
    </HomeBlogPostsContainer>
  );
};

export const query = graphql`
  query HomeBlogArticles {
    allContentfulArticleWeb(limit:3, sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        title
        category
        previewText
        duration
        slug
        bannerImage {
          gatsbyImageData
          id
        }
      }
    }
  }
`;

export type CategoryArticleCountType = {
  [value: string]: number;
};
