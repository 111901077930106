import * as React from 'react';
import { AnimatePresence } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import { easeInOutCubic } from 'services/animationHelpers';

import {
  CustomisationStonesAccordionContainer,
  CustomisationStonesAccordionItem,
  CustomisationStonesAccordionItemExtendedHeading,
  CustomisationStonesAccordionItemHeader,
  CustomisationStonesAccordionItemImageContainer,
  CustomisationStonesAccordionExtendedMotionDivItemContent,
  CustomisationStonesAccordionItemContentChemicalCompound,
  CustomisationStonesAccordionItemContentDescription,
  CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer,
  CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer,
} from './styled';

const accordionAnimationVariants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  collapsed: {
    opacity: 0,
    height: 0,
  },
};

export const CustomisationStonesAccordion: React.FC = () => {
  const [currentOpenItem, setCurrentOpenItem] = React.useState<boolean | number>(false);

  const toggleItem = (index: number) => {
    if (index === currentOpenItem) {
      setCurrentOpenItem(false);
    } else {
      setCurrentOpenItem(index);
    }
  };

  return (
    <CustomisationStonesAccordionContainer>
      <CustomisationStonesAccordionItem>
        <CustomisationStonesAccordionItemHeader
          onClick={() => toggleItem(0)}
        >
          <CustomisationStonesAccordionItemExtendedHeading>
            Amethyst
          </CustomisationStonesAccordionItemExtendedHeading>
          <CustomisationStonesAccordionItemImageContainer>
            <StaticImage
              src="../../../../assets/images/material_amethist.jpg"
              alt="Material Amethyst"
              placeholder="blurred"
              layout="fullWidth"
              quality={80}
              className="material"
            />
          </CustomisationStonesAccordionItemImageContainer>
        </CustomisationStonesAccordionItemHeader>
        <AnimatePresence initial={false}>
          <CustomisationStonesAccordionExtendedMotionDivItemContent
            animate={currentOpenItem === 0 ? 'open' : 'collapsed'}
            variants={accordionAnimationVariants}
            transition={{ duration: 0.4, ease: easeInOutCubic }}
          >
            <CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
              <CustomisationStonesAccordionItemContentChemicalCompound>
                SiO2
              </CustomisationStonesAccordionItemContentChemicalCompound>
              <CustomisationStonesAccordionItemContentDescription>
                <strong>Amethyst</strong> is the purple variety of the mineral Quartz family. It is found in igneous and metamorphic rock. Quartz which is a silicon ore is the most common mineral in the earth’s crust. It gains it’s purple colour through Earth’s naturally-occurring gamma rays. The Amethyst used for the NOWATCH comes from exclusively selected pieces from Western and Eastern India and is carefully shaped by our cutters in Jaipur.<br /><br />
                All pieces are entirely natural and will look unique.
              </CustomisationStonesAccordionItemContentDescription>
            </CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
            <CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
              <StaticImage
                src="../../../../assets/images/material_amethist_variations.png"
                alt="Amethyst variations"
                placeholder="blurred"
                layout="fullWidth"
                quality={70}
                className="variations"
                loading="eager"
              />
            </CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
          </CustomisationStonesAccordionExtendedMotionDivItemContent>
        </AnimatePresence>
      </CustomisationStonesAccordionItem>
      <CustomisationStonesAccordionItem>
        <CustomisationStonesAccordionItemHeader
          onClick={() => toggleItem(1)}
        >
          <CustomisationStonesAccordionItemExtendedHeading>
            Falcon Eye
          </CustomisationStonesAccordionItemExtendedHeading>
          <CustomisationStonesAccordionItemImageContainer>
            <StaticImage
              src="../../../../assets/images/material_falcon_eye.jpg"
              alt="Material Falcon Eye"
              placeholder="blurred"
              layout="fullWidth"
              quality={80}
              className="material"
            />
          </CustomisationStonesAccordionItemImageContainer>
        </CustomisationStonesAccordionItemHeader>
        <AnimatePresence initial={false}>
          <CustomisationStonesAccordionExtendedMotionDivItemContent
            animate={currentOpenItem === 1 ? 'open' : 'collapsed'}
            variants={accordionAnimationVariants}
            transition={{ duration: 0.4, ease: easeInOutCubic }}
          >
            <CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
              <CustomisationStonesAccordionItemContentChemicalCompound>
                SiO2
              </CustomisationStonesAccordionItemContentChemicalCompound>
              <CustomisationStonesAccordionItemContentDescription>
                <strong>The Falcon eye stone</strong> (or commonly known as Hawk's eye) comes from the Tiger Eye family of semi-precious quartz stones. The stone’s natural composition creates flat stripe-like slabs of rock within which a blue shade is formed, mixed with lines of iron and golden hues. A limited edition of rare Indian-origin deposits have been sourced for the NOWATCH collection and carefully shaped by our cutters in Jaipur.<br /><br />
                All pieces are entirely natural and will look unique.
              </CustomisationStonesAccordionItemContentDescription>
            </CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
            <CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
              <StaticImage
                src="../../../../assets/images/material_falcon_eye_variations.png"
                alt="Falcon Eye variations"
                placeholder="blurred"
                layout="fullWidth"
                quality={70}
                className="variations"
                loading="eager"
              />
            </CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
          </CustomisationStonesAccordionExtendedMotionDivItemContent>
        </AnimatePresence>
      </CustomisationStonesAccordionItem>
      <CustomisationStonesAccordionItem>
        <CustomisationStonesAccordionItemHeader
          onClick={() => toggleItem(2)}
        >
          <CustomisationStonesAccordionItemExtendedHeading>
            Labradorite
          </CustomisationStonesAccordionItemExtendedHeading>
          <CustomisationStonesAccordionItemImageContainer>
            <StaticImage
              src="../../../../assets/images/material_labradorite.jpg"
              alt="Material Labradorite"
              placeholder="blurred"
              layout="fullWidth"
              quality={80}
              className="material"
            />
          </CustomisationStonesAccordionItemImageContainer>
        </CustomisationStonesAccordionItemHeader>
        <AnimatePresence initial={false}>
          <CustomisationStonesAccordionExtendedMotionDivItemContent
            animate={currentOpenItem === 2 ? 'open' : 'collapsed'}
            variants={accordionAnimationVariants}
            transition={{ duration: 0.4, ease: easeInOutCubic }}
          >
            <CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
              <CustomisationStonesAccordionItemContentChemicalCompound>
                (Ca,Na)(Si,Al)4O8
              </CustomisationStonesAccordionItemContentChemicalCompound>
              <CustomisationStonesAccordionItemContentDescription>
                <strong>Labradorite</strong> stone displays an iridescent optical effect by the diffraction of light by the internal lamellar structure more commonly known as Labradorescence. The stone is formed in igneous, metamorphic, and sedimentary rocks and has been sourced from Jharkhand, Northern India and carefully shaped by our cutters in Jaipur.<br /><br />
                All pieces are entirely natural and will look unique.
              </CustomisationStonesAccordionItemContentDescription>
            </CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
            <CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
              <StaticImage
                src="../../../../assets/images/material_labradorite_variations.png"
                alt="Labradorite variations"
                placeholder="blurred"
                layout="fullWidth"
                quality={70}
                className="variations"
                loading="eager"
              />
            </CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
          </CustomisationStonesAccordionExtendedMotionDivItemContent>
        </AnimatePresence>
      </CustomisationStonesAccordionItem>
      <CustomisationStonesAccordionItem>
        <CustomisationStonesAccordionItemHeader
          onClick={() => toggleItem(3)}
        >
          <CustomisationStonesAccordionItemExtendedHeading>
            Lapis Lazuli
          </CustomisationStonesAccordionItemExtendedHeading>
          <CustomisationStonesAccordionItemImageContainer>
            <StaticImage
              src="../../../../assets/images/material_lapis_lazuli.jpg"
              alt="Material Lapis Lazuli"
              placeholder="blurred"
              layout="fullWidth"
              quality={80}
              className="material"
            />
          </CustomisationStonesAccordionItemImageContainer>
        </CustomisationStonesAccordionItemHeader>
        <AnimatePresence initial={false}>
          <CustomisationStonesAccordionExtendedMotionDivItemContent
            animate={currentOpenItem === 3 ? 'open' : 'collapsed'}
            variants={accordionAnimationVariants}
            transition={{ duration: 0.4, ease: easeInOutCubic }}
          >
            <CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
              <CustomisationStonesAccordionItemContentDescription>
                <strong>This ancient gem</strong> contains three minerals: Lazurite, Calcite and Pyrite. The stone has been famous through the millennia for its rich blue colour and golden coloured Pyrite veins. Lapis Lazuli has been intentionally sourced from the Badakhshan region and carefully shaped by our cutters in Jaipur.<br /><br />
                All pieces are entirely natural and will look unique.
              </CustomisationStonesAccordionItemContentDescription>
            </CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
            <CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
              <StaticImage
                src="../../../../assets/images/material_lapis_lazuli_variations.png"
                alt="Lapis Lazuli variations"
                placeholder="blurred"
                layout="fullWidth"
                quality={70}
                className="variations"
                loading="eager"
              />
            </CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
          </CustomisationStonesAccordionExtendedMotionDivItemContent>
        </AnimatePresence>
      </CustomisationStonesAccordionItem>
      <CustomisationStonesAccordionItem>
        <CustomisationStonesAccordionItemHeader
          onClick={() => toggleItem(4)}
        >
          <CustomisationStonesAccordionItemExtendedHeading>
            Malachite
          </CustomisationStonesAccordionItemExtendedHeading>
          <CustomisationStonesAccordionItemImageContainer>
            <StaticImage
              src="../../../../assets/images/material_malachite.jpg"
              alt="Material Malachite"
              placeholder="blurred"
              layout="fullWidth"
              quality={80}
              className="material"
            />
          </CustomisationStonesAccordionItemImageContainer>
        </CustomisationStonesAccordionItemHeader>
        <AnimatePresence initial={false}>
          <CustomisationStonesAccordionExtendedMotionDivItemContent
            animate={currentOpenItem === 4 ? 'open' : 'collapsed'}
            variants={accordionAnimationVariants}
            transition={{ duration: 0.4, ease: easeInOutCubic }}
          >
            <CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
              <CustomisationStonesAccordionItemContentChemicalCompound>
                Cu2CO3(OH)2
              </CustomisationStonesAccordionItemContentChemicalCompound>
              <CustomisationStonesAccordionItemContentDescription>
                <strong>Malachite</strong> stone is a mineral that is based on copper carbonate. This opaque, green-banded mineral crystallizes in the monoclinic crystal system, and most often forms botryoidal, fibrous, or stalagmitic masses, in fractures and deep, underground spaces. It has been sourced in Western Bengal, India and carefully shaped by our cutters in Jaipur.<br /><br />
                All pieces are entirely natural and will look unique.
              </CustomisationStonesAccordionItemContentDescription>
            </CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
            <CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
              <StaticImage
                src="../../../../assets/images/material_malachite_variations.png"
                alt="Malachite variations"
                placeholder="blurred"
                layout="fullWidth"
                quality={70}
                className="variations"
                loading="eager"
              />
            </CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
          </CustomisationStonesAccordionExtendedMotionDivItemContent>
        </AnimatePresence>
      </CustomisationStonesAccordionItem>
      <CustomisationStonesAccordionItem>
        <CustomisationStonesAccordionItemHeader
          onClick={() => toggleItem(5)}
        >
          <CustomisationStonesAccordionItemExtendedHeading>
            Tiger’s Eye
          </CustomisationStonesAccordionItemExtendedHeading>
          <CustomisationStonesAccordionItemImageContainer>
            <StaticImage
              src="../../../../assets/images/material_tigers_eye.jpg"
              alt="Material Tiger’s Eye"
              placeholder="blurred"
              layout="fullWidth"
              quality={80}
              className="material"
            />
          </CustomisationStonesAccordionItemImageContainer>
        </CustomisationStonesAccordionItemHeader>
        <AnimatePresence initial={false}>
          <CustomisationStonesAccordionExtendedMotionDivItemContent
            animate={currentOpenItem === 5 ? 'open' : 'collapsed'}
            variants={accordionAnimationVariants}
            transition={{ duration: 0.4, ease: easeInOutCubic }}
          >
            <CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
              <CustomisationStonesAccordionItemContentChemicalCompound>
                SiO2
              </CustomisationStonesAccordionItemContentChemicalCompound>
              <CustomisationStonesAccordionItemContentDescription>
                <strong>Tiger’s Eye</strong> is a mineral mixture of brown and yellowish colors. It is composed of quartz (as a main component), limonite and riebeckite. It is a gemstone that displays a chatoyancy (cat’s-eye), when polished and moves back and forth in incident light. It is sourced from Rajasthan, India and carefully shaped by our cutters in Jaipur.<br /><br />
                All pieces are entirely natural and will look unique.
              </CustomisationStonesAccordionItemContentDescription>
            </CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
            <CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
              <StaticImage
                src="../../../../assets/images/material_tigers_eye_variations.png"
                alt="Tiger’s Eye variations"
                placeholder="blurred"
                layout="fullWidth"
                quality={70}
                className="variations"
                loading="eager"
              />
            </CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
          </CustomisationStonesAccordionExtendedMotionDivItemContent>
        </AnimatePresence>
      </CustomisationStonesAccordionItem>
      <CustomisationStonesAccordionItem>
        <CustomisationStonesAccordionItemHeader
          onClick={() => toggleItem(6)}
        >
          <CustomisationStonesAccordionItemExtendedHeading>
            Rose Quartz
          </CustomisationStonesAccordionItemExtendedHeading>
          <CustomisationStonesAccordionItemImageContainer>
            <StaticImage
              src="../../../../assets/images/material_rose_quartz.jpg"
              alt="Material Rose Quartz"
              placeholder="blurred"
              layout="fullWidth"
              quality={80}
              className="material"
            />
          </CustomisationStonesAccordionItemImageContainer>
        </CustomisationStonesAccordionItemHeader>
        <AnimatePresence initial={false}>
          <CustomisationStonesAccordionExtendedMotionDivItemContent
            animate={currentOpenItem === 6 ? 'open' : 'collapsed'}
            variants={accordionAnimationVariants}
            transition={{ duration: 0.4, ease: easeInOutCubic }}
          >
            <CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
              <CustomisationStonesAccordionItemContentChemicalCompound>
                SiO2
              </CustomisationStonesAccordionItemContentChemicalCompound>
              <CustomisationStonesAccordionItemContentDescription>
                Coming from the <strong>quartz family</strong> it has a light pink colour and is formed in large pieces abundantly all over the world. NOWATCH has sourced the rose quartz from multiple mines in the Rajasthan region, India and they are carefully shaped by our cutters in Jaipur.<br /><br />
                All pieces are entirely natural and will look unique.
              </CustomisationStonesAccordionItemContentDescription>
            </CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
            <CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
              <StaticImage
                src="../../../../assets/images/material_rose_quartz_variations.png"
                alt="Rose Quartz variations"
                placeholder="blurred"
                layout="fullWidth"
                quality={70}
                className="variations"
                loading="eager"
              />
            </CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
          </CustomisationStonesAccordionExtendedMotionDivItemContent>
        </AnimatePresence>
      </CustomisationStonesAccordionItem>
      <CustomisationStonesAccordionItem>
        <CustomisationStonesAccordionItemHeader
          onClick={() => toggleItem(7)}
        >
          <CustomisationStonesAccordionItemExtendedHeading>
            White Agate
          </CustomisationStonesAccordionItemExtendedHeading>
          <CustomisationStonesAccordionItemImageContainer>
            <StaticImage
              src="../../../../assets/images/material_white_agate.jpg"
              alt="Material White Agate"
              placeholder="blurred"
              layout="fullWidth"
              quality={80}
              className="material"
            />
          </CustomisationStonesAccordionItemImageContainer>
        </CustomisationStonesAccordionItemHeader>
        <AnimatePresence initial={false}>
          <CustomisationStonesAccordionExtendedMotionDivItemContent
            animate={currentOpenItem === 7 ? 'open' : 'collapsed'}
            variants={accordionAnimationVariants}
            transition={{ duration: 0.4, ease: easeInOutCubic }}
          >
            <CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
              <CustomisationStonesAccordionItemContentChemicalCompound>
                SiO2
              </CustomisationStonesAccordionItemContentChemicalCompound>
              <CustomisationStonesAccordionItemContentDescription>
                <strong>Agate</strong> is a cryptocrystalline variety of silica, mainly chalcedony, characterised by its fineness of grain and brightness of color. It is mainly formed within volcanic rocks or ancient lavas, where siliceous matter fills former cavities of the original molten mass. It is sourced from Western India and carefully shaped by our cutters in Jaipur.<br /><br />
                All pieces are entirely natural and will look unique.
              </CustomisationStonesAccordionItemContentDescription>
            </CustomisationStonesAccordionExtendedMotionDivItemContentTextContainer>
            <CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
              <StaticImage
                src="../../../../assets/images/material_white_agate_variations.png"
                alt="White Agate variations"
                placeholder="blurred"
                layout="fullWidth"
                quality={70}
                className="variations"
                loading="eager"
              />
            </CustomisationStonesAccordionExtendedMotionDivItemContentImageContainer>
          </CustomisationStonesAccordionExtendedMotionDivItemContent>
        </AnimatePresence>
      </CustomisationStonesAccordionItem>
    </CustomisationStonesAccordionContainer>
  );
};
