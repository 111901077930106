import * as React from 'react';

import imageStress from 'images/sensor_block_stress.jpeg';
import imageSleep  from 'images/sensor_block_sleep.jpeg';
import imageActivity from 'images/sensor_block_activity.jpeg';
import { StaticImage } from 'gatsby-plugin-image';
import { useChangeHeaderColor } from 'services/hooks';

import theme from 'styles/theme';
import { SensorsContainer, SensorsCard, SensorsCardImageWrapper, SensorsCardContentWrapper, ExtendedHeading, ExtendedText, ExtendedButton } from './styled';

export const SensorsBlock = () => {
  const [elementRef] = useChangeHeaderColor({
    background: theme.colors.sand.lighter,
    text: theme.colors.black,
  });
  const sensorsData: SensorsDataObj[] = [
    {
      title: 'movement',
      text: 'Check your movement. And see how it’s making you feel today.',
      target: '/technology#movement',
      image: imageActivity,
    },
    {
      title: 'stress',
      text: 'What does your skin say about your StressLevel?',
      target: '/technology#stress',
      image: imageStress,
    },
    {
      title: 'sleep',
      text: 'Wake up fresh with the latest sleep algorithms.',
      target: '/technology#sleep',
      image: imageSleep,
    },
  ];

  return (
    <SensorsContainer ref={elementRef}>
      {sensorsData.map(({ title, text, target, image }) => (
        <SensorsCard $image={image} key={title}>
          <ExtendedHeading margin="0" isCentered>{title}</ExtendedHeading>
          <ExtendedText>{text}</ExtendedText>
          <ExtendedButton to={target}>{`More about ${title}`}</ExtendedButton>
        </SensorsCard>
      ))}
    </SensorsContainer>
  );
};

type SensorsDataObj = {
  title: string;
  text: string;
  target: string;
  image: string;
};
