import * as React from 'react';

import { ContentBlocksContainer } from 'modules/contentBlocks';
import { HomeTechnology, SensorsBlock } from 'modules/homeContentBlocks';
import { SensorsExplodedView } from 'modules/sensors/SensorsExplodedView';

export const CampaignTechnologyBlock: React.FC = () => {
  return (
    <ContentBlocksContainer>
      <HomeTechnology />
      <SensorsBlock />
      <SensorsExplodedView />
    </ContentBlocksContainer>
  );
};
