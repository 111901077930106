type deviceType = 'mobile' | 'desktop';

export class AnimationBase {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  worker: Worker;

  device: deviceType;

  desktopWidth: number;
  desktopHeight: number;
  mobileWidth: number;
  mobileHeight: number;
  currentWidth: number;
  currentHeight: number;

  desktopFrameCount: number;
  mobileFrameCount: number;
  currentFrameCount: number;

  images: HTMLImageElement[];

  addAssetToPreload: (number?: number) => void;
  addLoadedAsset: (number?: number) => void;

  constructor(
    canvas: HTMLCanvasElement,
    device: deviceType,
    addAssetToPreload: (number?: number) => void,
    addLoadedAsset: (number?: number) => void,
    worker: Worker,
  ) {
    this.canvas = canvas;
    this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    this.worker = worker;
    this.device = device;

    this.desktopWidth = 2000;
    this.desktopHeight = 1138;
    this.mobileWidth = 788;
    this.mobileHeight = 1400;
    this.currentWidth = this.canvas.width = (device === 'mobile') ? this.mobileWidth : this.desktopWidth;
    this.currentHeight = this.canvas.height = (device === 'mobile') ? this.mobileHeight : this.desktopHeight;

    this.desktopFrameCount = 234;
    this.mobileFrameCount = 333;
    this.currentFrameCount = (device === 'mobile') ? this.mobileFrameCount : this.desktopFrameCount;

    this.images = [];

    this.addAssetToPreload = addAssetToPreload;
    this.addLoadedAsset = addLoadedAsset;
  }

  changeDevice(device: deviceType) {
    // Very important! Otherwise the preload can be triggered way too often.
    if (device === this.device) return;

    this.device = device;

    this.currentWidth = this.canvas.width = (device === 'mobile') ? this.mobileWidth : this.desktopWidth;
    this.currentHeight = this.canvas.height = (device === 'mobile') ? this.mobileHeight : this.desktopHeight;

    this.preloadImages();
  }

  preloadImages() {
    this.addAssetToPreload(1);
    const imageUrls = [];

    // Once again, it's possible that messages could be returned before the
    // listener is attached, so we need to attach the listener before we pass
    // image URLs to the web worker
    this.worker.addEventListener('message', (event) => {
      // Grab the message data from the event
      const imageUrls = event.data.images;

      for (let i = 0; i <= imageUrls.length - 1; i++) {
        const img = new Image();
        img.setAttribute('src', imageUrls[i]);

        this.images.push(img);
      }

      this.addLoadedAsset();
    });

    for (let i = 1; i <= this.currentFrameCount; i++) {
      const imageUrl = this.currentFrame(i);
      imageUrls.push(imageUrl);
    }

    this.worker.postMessage(imageUrls);
  }

  currentFrame(index: number) {
    return (this.device === 'mobile') ? (
      `/exploded/mobile/EXPLODEDMOBILE${index.toString().padStart(3, '0')}.webp`
    ) : (
      `/exploded/desktop/EXPLODED.${index.toString().padStart(3, '0')}.webp`
    );
  }

  update(scrollFraction: number) {
    // Calculate the current framenumber based on the scroll fraction
    const frameIndex = Math.min(
      this.currentFrameCount - 1,
      Math.ceil(scrollFraction * this.currentFrameCount),
    );

    // In the case the current frame isn't loaded yet, trigger to draw it on
    // load so there's no empty canvas.
    if (this.images[frameIndex]?.complete) {
      this.context.drawImage(this.images[frameIndex], 0, 0);
    } else if (this.images[frameIndex]) {
      this.images[frameIndex].onload = () => {
        this.context.drawImage(this.images[frameIndex], 0, 0);

        // Define again, because previous onLoad on this image object
        // is overwritten by this function
        this.addLoadedAsset();
      };
    }
  }
}
