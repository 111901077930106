import styled from 'styled-components';

import { media } from 'styles/utils';
import { Text, Heading } from 'common/typography';

export const CustomisationStrapsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  margin: 0 0 40% 0;
  overflow: hidden;

  ${media.tablet`
    margin: 10% 0 20% 0;
  `}

  ${media.desktop`
    margin: 5% 0 20% 0;
    min-height: 50vh;
    flex-direction: row;
  `}
`;

export const CustomisationTextContainer = styled.div`
  padding: 0px 6vw;
  margin-bottom: 13%;

  ${media.tablet`
    padding: 0 10vw;
  `}

  ${media.desktop`
    padding: 0 5vw;
    margin-bottom: 0;
    flex-grow: 1;
  `}
`;

export const ExtendedTextDescription = styled(Text)`
  font-size: 4vw;
  line-height: 5.5vw;
  max-width: 100%;

  ${media.tablet`
    font-size: 3vw;
  `}

  ${media.desktop`
    font-size: 1.6vw;
    max-width: 90%;
  `}
`;

export const CustomisationStrapsImageContainer = styled.div`
  width: 60vw;

  ${media.desktop`
    flex-shrink: 0;
    width: 35vw;
    margin: 0 10vw;
  `}
`;

export const ExtendedHeadingFinish = styled(Heading)`
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  font-size: 5vw;
  margin: 0;
`;

export const ExtendedTextFinishesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4vw;
  justify-content: space-evenly;

  ${media.desktop`
    flex-direction: column;
    justify-content: initial;

    ${ExtendedHeadingFinish}:not(:first-of-type) {
      margin-top: 1vw;
    }
  `}
`;

