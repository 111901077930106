import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const CustomisationRemovableWatchfaceContainer = styled.div`
  width: 100%;
  padding: 0 2vw 0;
  margin: 20vh 0;
`;

export const CustomisationRemovableWatchfaceImageContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  z-index: 0;
`;

export const CustomisationRemovableWatchfaceExtendedMotionImageWrapper = styled(motion.div)`
  height: 96vw;

  ${media.tablet`
    height: 40vh;
  `}

  ${media.desktop`
    height: 65vh;
  `}

  .desktop_image, .mobile_image {
    pointer-events: none;
    user-select: none;
  }

  .desktop_image {
    display: none;
    height: 75vh;

    ${media.desktop`
      display: block;
    `}
  }

  .mobile_image {
    height: 106vw;

    ${media.tablet`
      height: 50vh;
    `}

    ${media.desktop`
      display: none;
    `}
  }
`;

export const CustomisationRemovableWatchfaceTextContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;

  ${media.desktop`
    align-items: flex-end;
    justify-content: center;
  `}
`;


export const CustomisationRemovableWatchfaceTextWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 2vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${media.desktop`
    align-items: flex-start;
    text-align: left;
    position: relative;
    padding-right: 15vw;
    top: 0;
  `}
`;

export const ExtendedText = styled(Text)`
  font-size: 4vw;
  line-height: 4vw;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 3vw;
  text-align: center;

  ${media.desktop`
    text-align: left;
    font-size: 5vw;
    text-transform: uppercase;
    line-height: 100%;
  `}
`;
