import * as React from 'react';

import theme from 'styles/theme';
import { StaticButtonText } from 'common/typography';
import { useChangeHeaderColor } from 'services/hooks';

import { HomePhilips } from '..';
import {
  HomeTechnologyContainer,
  HomeTechnologyHeaderWrapper,
  ExtendedButton,
  ExtendedStaticHeading,
  ExtendedText,
} from './styled';

export const HomeTechnology: React.FC = () => {
  const [elementRef] = useChangeHeaderColor({
    background: theme.colors.sand.lighter,
    text: theme.colors.black,
  });
  return (
    <HomeTechnologyContainer ref={elementRef}>
      <HomeTechnologyHeaderWrapper>
        <ExtendedStaticHeading as="h2" variant="display" $uppercase>
          Technology<br /> for humanity
        </ExtendedStaticHeading>
        <ExtendedText $isCentered $lineHeight={170}>
          What happens when you combine Philips’ advanced biotechnologies with the leading science around mindset and mental wellbeing?
        </ExtendedText>
        <ExtendedButton
          variant="primary"
          to="/technology"
        >
          <StaticButtonText>
            Learn more about technology
          </StaticButtonText>
        </ExtendedButton>
      </HomeTechnologyHeaderWrapper>
      <HomePhilips />
    </HomeTechnologyContainer>
  );
};
