import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';
import { Text } from 'common/typography';
import LogoNowatch from 'vectors/internal/logo_nowatch.svg';
import LogoPhilips from 'vectors/internal/logo_philips.svg';

export const IntroductionPhilipsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 55vh;
  overflow: hidden;
`;

export const LogoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
`;

export const ExtendedLogoNowatch = styled(LogoNowatch)`
  width: 30vw;
  left: -32vw;
  position: absolute;
  z-index: 1;

  ${media.tablet`
    width: 20vw;
    left: -20vw;
  `}

  ${media.desktop`
    height: auto;
    width: 9vw;
    left: -11vw;
  `}
`;

export const ExtendedLogoPhilips = styled(LogoPhilips)`
  width: 22vw;
  right: -32vw;
  position: absolute;
  z-index: 1;

  ${media.tablet`
    width: 15vw;
    right: -20vw;
  `}

  ${media.desktop`
    right: auto;
    width: 10vw;
    height: auto;
    right: -12vw;
  `}
`;

export const ExtendedTextDescription = styled(Text)`
  font-size: 4vw;
  max-width: 80%;
  text-align: center;

  ${media.tablet`
    font-size: 3vw;
  `}

  ${media.desktop`
    font-size: 1vw;
    padding: 0 42px;
    max-width: 50%;
    line-height: 150%;
  `}
`;

export const ExtendedMotionDiv = styled(motion.div)`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExtendedMotionDivText = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;

  ${media.desktop`
    margin-top: 1%;
  `}

  ${media.large`
    margin-top: 3%;
  `}
`;
