import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';
import { StaticHeading, Text } from 'common/typography';
import { Button } from 'common/interaction';

export const HomeProductContainer = styled.div`
  width: 100%;
  margin: auto;
  padding: 10vh 16px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 10vh;

  ${media.desktop`
    flex-direction: row;
    gap: 60px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
    padding: 10vh 0 0;
  `}
`;

export const ExtendedText = styled(Text)`
  ${media.desktop`
    max-width: 500px;
  `}
`;

export const ExtendedStaticHeading = styled(StaticHeading)`
  font-size: 48px;
  max-width: 80vw;
  margin: 0;

  ${media.desktop`
    max-width: 100vw;
    font-size: 88px;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
`;

export const ExtendedButton = styled(Button)`
  height: auto;
  padding: 14px 24px;
  width: fit-content;
`;

export const ProductImageWrapper = styled.div`
  border-radius: 20px;
  user-select: none;
  pointer-events: none;
  margin-bottom: 6vw;
  overflow: hidden;
  position: relative;

  ${media.desktop`
    width: 32vw;
    margin-bottom: 0;
  `}
`;
