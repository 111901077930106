import * as React from 'react';

import { StaticButtonText } from 'common/typography';

import {
  HomeAtelierStepNavigationWrapper,
  HomeAtelierStepNowatchNavigationContainer,
  ExtendedNumber,
  ExtendedStaticText,
  HorizontalRule,
} from './styled';

export const HomeAtelierDemoSteps = () => {
  return (
    <HomeAtelierStepNowatchNavigationContainer>
      <HomeAtelierStepNavigationWrapper>
        <ExtendedNumber>
          <StaticButtonText>
            1
          </StaticButtonText>
        </ExtendedNumber>
        <ExtendedStaticText $size="sm">
          Case
        </ExtendedStaticText>
      </HomeAtelierStepNavigationWrapper>
      <HorizontalRule />
      <HomeAtelierStepNavigationWrapper>
        <ExtendedNumber>
          <StaticButtonText>
            2
          </StaticButtonText>
        </ExtendedNumber>
        <ExtendedStaticText $size="sm">
          Strap
        </ExtendedStaticText>
      </HomeAtelierStepNavigationWrapper>
      <HorizontalRule />
      <HomeAtelierStepNavigationWrapper>
        <ExtendedNumber>
          <StaticButtonText>
            3
          </StaticButtonText>
        </ExtendedNumber>
        <ExtendedStaticText $size="sm">
          Disc
        </ExtendedStaticText>
      </HomeAtelierStepNavigationWrapper>
      <HorizontalRule />
      <HomeAtelierStepNavigationWrapper>
        <ExtendedNumber>
          <StaticButtonText>
            4
          </StaticButtonText>
        </ExtendedNumber>
        <ExtendedStaticText $size="sm">
          Membership
        </ExtendedStaticText>
      </HomeAtelierStepNavigationWrapper>
    </HomeAtelierStepNowatchNavigationContainer>
  );
};
