import * as React from 'react';
import { useTransform, useMotionTemplate } from 'framer-motion';

import { useScrollDistanceForElementInView, useChangeHeaderColor } from 'services/hooks';

import { StaticButtonText } from 'common/typography';
import stressLevels from 'images/parallax_card-stress_levels.png';
import heartRate from 'images/parallax_card-heart_rate.png';
import stressHeatmap from 'images/parallax_card-stress_heatmap.png';
import heartRateOverview from 'images/parallax_card-heart_rate_overview.png';
import visual from 'images/parallax_card-visual.png';
import activity from 'images/parallax_card-activity.png';
import visualOverlay from 'images/parallax_card-visual_overlay.png';
import sleep from 'images/parallax_card-sleep.png';
import sleepWeek from 'images/parallax_card-sleep_week.png';

import theme from 'styles/theme';
import { ParallaxCard } from './components/ParallaxCard';
import {
  ExtendedStaticHeading,
  HomeAppHeaderWrapper,
  ExtendedText,
  ExtendedButton,
  AppBlocksContainer,
  HomeAppContainer,
} from './styled';

export const HomeApp: React.FC = () => {
  const [heightRef, scrolledDistance] = useScrollDistanceForElementInView();
  const [elementRef] = useChangeHeaderColor({
    background: theme.colors.sand.lighter,
    text: theme.colors.black,
  });
  const columnsToRender = [
    { images: [stressLevels, heartRate], transformValues: [600, -100] },
    { images: [stressHeatmap, heartRateOverview], transformValues: [500, -300] },
    { images: [visual], transformValues: [100, -260] },
    { images: [activity, visualOverlay], transformValues: [-60, 140] },
    { images: [sleep, sleepWeek], transformValues: [440, -160] },
  ];

  const headerPositionY = useTransform(
    scrolledDistance,
    [0, 0.9],
    [200, -300],
  );
  const templateHeaderPositionY = useMotionTemplate`translateY(${headerPositionY}px)`;

  return (
    <div ref={heightRef}>
      <HomeAppContainer ref={elementRef}>
        <HomeAppHeaderWrapper style={{ transform: templateHeaderPositionY }}>
          <ExtendedStaticHeading as="h2" variant="display" $uppercase>
            The NOWATCH App
          </ExtendedStaticHeading>
          <ExtendedText $isCentered $lineHeight={170}>
            Take a moment to see what’s happening inside. Are you at peace, excited, overwhelmed?<br /> The NOWATCH app is your compass for checking in on mind and body, and accessing your recommendations for restoring balance.
          </ExtendedText>
          <ExtendedText $isCentered $lineHeight={170}>
            Beautiful intuitive visualisations make visiting the app a time to enjoy, self-reflect, and learn how to reconnect with your body.
          </ExtendedText>

          <ExtendedButton
            variant="primary"
            to="/technology"
          >
            <StaticButtonText>
              Discover the app
            </StaticButtonText>
          </ExtendedButton>
        </HomeAppHeaderWrapper>
        <AppBlocksContainer>
          {columnsToRender.map((card, index) =>
            <ParallaxCard
              key={index}
              images={card.images}
              transformValues={card.transformValues}
              scrolledDistance={scrolledDistance}
            />,
          )}
        </AppBlocksContainer>
      </HomeAppContainer>
    </div>
  );
};
