import styled from 'styled-components';

import { media } from 'styles/utils';
import { Text, StaticHeading } from 'common/typography';
import { Button } from 'common/interaction';

export const HomeBlogPostsContainer = styled.div`
  width: 100%;
  padding: 10vh 0 0;
  background-color: ${({ theme }) => theme.colors.sand.lighter};
  border-radius: 0 0 24px 24px;

  ${media.desktop`
    gap: 80px;
    padding: 140px 0 0 0;
  `}

  ${media.large`
    padding: 140px 0 0 0;
  `}
`;

export const InterestingArticlesContainer = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 80px 0 100px 0;

  > h3 {
    text-align: center;
  }

  ${media.desktop`
    max-width: 1400px;
    margin: auto;
  `}
`;

export const ArticlesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 40px;

  section:not(:first-of-type){
    display: none;
  }

  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: start;

    section:not(:first-of-type){
      display: flex;
    }
  `}
`;

export const HomeBlogHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 140px;
`;

export const ExtendedStaticHeading = styled(StaticHeading)`
  margin: 0;
  text-align: center;

  ${media.desktop`
    font-size: 6.5vw;
  `}
`;

export const ExtendedText = styled(Text)`
  max-width: 800px;
`;

export const ExtendedButton = styled(Button)`
  height: auto;
  padding: 14px 24px;
`;
