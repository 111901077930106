import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

import { Heading, Text } from 'common/typography';
import { Button } from 'common/interaction';
// WIP: typographic components should have one clear sizing system. Will be picked up in the next iteration.

export const ExtendedButton = styled(Button)`
  padding: 12px 24px;
  font-size: 16px;
  text-transform: none;
  margin: auto 0 0;
`;

export const ExtendedText = styled(Text)`
  font-size: 18px;
  line-height: 23.5px;
  max-width: 30ch;
  min-height: 40px;
  color: ${({ theme }) => theme.colors.white};
`;

export const ExtendedHeading = styled(Heading)`
  font-weight: 400;
  font-size: 48px;
  line-height: 100%;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};

  ${media.desktop`
    font-size: 64px;
    margin: 0 0 12px;
  `}
`;

export const SensorsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.sand.lighter};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 16px;
  border-radius: 0 0 20px 20px;

  ${media.tablet`
    flex-direction: row;
    gap: 32px;
    justify-content: center;
  `}
`;

export const SensorsCard = styled.div<SensorsCardProps>`
  padding: 48.5px 16px;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.sand.lighter};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${({ $image }) => $image && css`
    background-image: url(${$image});
  `}

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 295px;
  width: 100%;

  + div {
    margin-top: 36px;
  }

  ${media.desktop`
    padding: 96px 0;
    height: 100%;
    max-width: 480px;
    width: 480px;
    display: flex;
    max-height: 400px;
    justify-content: center;
    align-items: center;

    + div {
      margin-top: 0;
    }
  `}
`;

type SensorsCardProps = {
  $image: string;
}
