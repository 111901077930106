import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/utils';

export const CustomisationInterchangabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 5vw;
`;

export const CustomisationInterchangabilityWatchContainer = styled.div`
  width: 90vw;
  position: absolute;
  left: 5vw;

  .watch_overlay,
  .watch_case,
  .watch {
    position: absolute;
    z-index: 3;
    width: 90vw;
  }

  .watch_overlay {
    z-index: 4;
  }

  .watch_case {
    z-index: 2;
  }

  ${media.desktop`
    width: 25vw;
    left: 33.33vw;

    .watch_overlay, .watch_case, .watch {
      width: 33.33vw;
    }
  `}
`;

export const ExtendedMotionCustomisationInterchangabilityStonesContainer = styled(motion.div)`
  position: absolute;
  z-index: 3;
  top: 5vw;

  ${media.desktop`
    top: 0;
  `}
`;

export const CustomisationInterchangabilityStonesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .stone {
    margin: 0 -12vw;
    width: 90vw;
    flex-shrink: 0;
  }

  ${media.desktop`
    .stone {
      margin: 0 0vw;
      width: 33.33vw;
    }
  `}
`;

export const CustomisationInterchangabilityOverflowWrapper = styled.div`
  overflow: hidden;
  padding-top: 35vw;
  position: relative;
  height: 115vw;
  display: flex;

  ${media.desktop`
    padding-top: 14vw;
    height: 48vw;
  `}
`;
