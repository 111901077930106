import styled from 'styled-components';

import { media } from 'styles/utils';
import { Heading, Text } from 'common/typography';

export const CustomisationSpecsWrapper = styled.div`
  display: flex;
  margin: 5% 0 10vh;
  padding: 0 3vw;
  flex-direction: column;

  ${media.desktop`
    padding: 0 0 0 5vw;
  `}
`;

export const CustomisationSpecsContainer = styled.div`
  ${media.desktop`
    margin-left: 32vw;
  `}
`;

export const CustomisationSpecsExtendedHeading = styled(Heading)`
  font-size: 28vw;

  ${media.desktop`
    font-size: 8vw;
  `}
`;

export const ExtendedTextDescription = styled(Text)`
  font-size: 4vw;
  line-height: 5.5vw;
  max-width: 100%;
  margin-bottom: 15vw;

  ${media.tablet`
    font-size: 3vw;
    line-height: 5vw;
  `}

  ${media.desktop`
    font-size: 1.6vw;
    line-height: 2.5vw;
    max-width: 90%;
    margin-bottom: 10vw;
  `}

  ${media.large`
    font-size: 1.4vw;
    line-height: 2vw;
    margin-bottom: 8vw;
  `}
`;

export const CustomisationSpecsSpecContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  padding: 5vw 0;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    text-align: right;
    font-size: 4vw;
    line-height: 5.5vw;
  }

  ${media.tablet`
    li {
      font-size: 3vw;
      line-height: 5vw;
    }
  `}

  ${media.desktop`
    padding: 2vw 5vw 3.5vw 0;
    display: flex;
    flex-direction: row;

    ul {
      flex-shrink: 0;
    }

    li {
      font-size: 1.6vw;
      line-height: 2.5vw;
    }
  `}

  ${media.large`
    li {
      font-size: 1.3vw;
      line-height: 1.6vw;
    }
  `}
`;

export const CustiomisationSpecsSpecExtendedHeading = styled(Heading).attrs(() => ({
  as: 'h2',
}))`
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  margin: 0 0 5vw 0;

  ${media.desktop`
    margin: 0;
    width: 100%;
  `}

  ${media.large`
    font-size: 2.5vw;
  `}
`;
