import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { StaticButtonText } from 'common/typography';
import { useDevice } from 'services/hooks';

import {
  HomeProductContainer,
  ExtendedStaticHeading,
  ExtendedText,
  ExtendedButton,
  ProductImageWrapper,
  TextWrapper,
} from './styled';

export const HomeProduct: React.FC = () => {
  const { isMobile } = useDevice();

  return (
    <HomeProductContainer>
      <ProductImageWrapper>
        {isMobile ?
          (<StaticImage
            src="../../../../assets/images/finger_with_blue_stone-mobile.jpg"
            alt="NOWATCH Lapis Lazuli"
            placeholder="blurred"
            quality={90}
            objectFit="cover"
            className="home-text-and-image-block_image"
          />
          ) : (
            <StaticImage
              src="../../../../assets/images/finger_with_blue_stone-desktop.jpg"
              alt="NOWATCH Lapis Lazuli"
              placeholder="blurred"
              quality={90}
              objectFit="cover"
              className="home-text-and-image-block_image"
            />
          )}
      </ProductImageWrapper>
      <TextWrapper>
        <ExtendedStaticHeading as="h2" variant="display" $uppercase>
          The Product
        </ExtendedStaticHeading>
        <ExtendedText $lineHeight={170}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Tellus sagittis scelerisque vitae sed rhoncus mi commodo volutpat ipsum.
          Est est vitae fermentum, auctor quam cursus sed tortor.
        </ExtendedText>
        <ExtendedButton
          variant="primary"
          // TODO: get the actual url for the corresponfing product page... possibly from contentful?
          to="/#"
        >
          <StaticButtonText>
            Go to product page
          </StaticButtonText>
        </ExtendedButton>
      </TextWrapper>
    </HomeProductContainer>
  );
};
