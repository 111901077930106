export const easeOutSine = (x: number): number => {
  return Math.sin((x * Math.PI) / 2);
};

export const easeInOutCubic = (x: number): number => {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
};

export const easeInCubic = (x: number): number => {
  return x * x * x;
};
