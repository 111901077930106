import styled from 'styled-components';

import { StaticHeading, Text } from 'common/typography';
import { media } from 'styles/utils';
import { Button } from 'common/interaction';

export const HomeAtelierContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10vh 10vw;
  overflow-x: hidden;
  gap: 32px;
  background-color: ${({ theme }) => theme.colors.springWood};
  border-radius: 20px;
  margin: 0 0 -20px 0;

  ${media.desktop`
    gap: 120px;
    padding: 100px 0;
  `}

  ${media.large`
    gap: 140px;
    padding: 140px 0;
  `}
`;

export const HomeAtelierHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const HomeAtelierFooterWrapper = styled(HomeAtelierHeaderWrapper)`
  gap: 32px;
`;

export const ExtendedStaticHeading = styled(StaticHeading)`
  margin: 0;
  text-align: center;

  ${media.desktop`
    font-size: 6.5vw;
  `}
`;

export const ExtendedText = styled(Text)`
  max-width: 800px;
`;

export const MiddleWatch = styled.img`
  height: 250px;
  display: block;
  position: absolute;
  left: calc(50% - 125px);
  z-index: 6;

  ${media.large`
    height: 300px;
    left: calc(50% - 150px);
  `}
`;

export const HomeAtelierStaticImageContainer = styled.div`
  width: 65vw;
  margin-bottom: 10vw;
  max-width: 1440px;

  ${media.desktop`
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    div:nth-of-type(4){
      z-index: 5;
    }
    div:nth-of-type(5){
      z-index: 4;
    }
  `}
`;

export const ExtendedButton = styled(Button)`
  height: auto;
  padding: 14px 24px;
`;
