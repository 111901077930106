import * as React from 'react';
import { useTransform, useMotionTemplate, MotionValue } from 'framer-motion';

import { useDevice } from 'services/hooks';
import { easeOutSine } from 'services/animationHelpers';

import BarometerIcon from 'vectors/internal/barometer.svg';
import HRVIcon from 'vectors/internal/hrv.svg';
import CortisolIcon from 'vectors/internal/cortisol.svg';
import SPO2Icon from 'vectors/internal/spo2.svg';
import TemperatureIcon from 'vectors/internal/temperature.svg';
import BreathRateIcon from 'vectors/internal/breath_rate.svg';

import {
  SensorsSensorListContainer,
  SensorsSensorListSection,
  SensorsSensorListSectionItem,
  IconContainer,
  TextWrapper,
  BadgeContainer,
  BadgeTextWrapper,
  ExtendedMotionDiv,
} from './styled';

// Because this component has position:absolute on large screens, we can't use the
// useScrollDistanceForElementInView here, as it will give incorrect calculations.
// So we use the scrolledDistance of the parent element to animate!
export const SensorsSensorList: React.FC<SensorsSensorListProps> = ({ scrolledDistance }) => {
  const { isLarge } = useDevice();

  const yContainerSmall = useTransform(scrolledDistance, [0.65, 0.75], [40, 0], {
    ease: easeOutSine,
  });
  const transformYContainerSmall = useMotionTemplate`translateY(${yContainerSmall}vh)`;

  const yContainerLarge = useTransform(scrolledDistance, [0.1, 1], [40, -50], {
    ease: easeOutSine,
  });
  const transformYContainerLarge = useMotionTemplate`translateY(${yContainerLarge}vh)`;

  return (
    <SensorsSensorListContainer>
      <ExtendedMotionDiv
        style={{
          transform: isLarge ? transformYContainerLarge : transformYContainerSmall,
        }}
      >
        <SensorsSensorListSection>
          <SensorsSensorListSectionItem>
            <IconContainer>
              <BreathRateIcon className="breathrate" />
            </IconContainer>
            <TextWrapper>Breath Rate</TextWrapper>
          </SensorsSensorListSectionItem>
          <SensorsSensorListSectionItem>
            <IconContainer>
              <HRVIcon className="hrv" />
            </IconContainer>
            <TextWrapper>Heart Rate</TextWrapper>
          </SensorsSensorListSectionItem>
          <SensorsSensorListSectionItem>
            <IconContainer>
              <TemperatureIcon className="temperature" />
            </IconContainer>
            <TextWrapper>
              Temperature
              <BadgeContainer>
                <BadgeTextWrapper>Coming Soon</BadgeTextWrapper>
              </BadgeContainer>
            </TextWrapper>
          </SensorsSensorListSectionItem>
        </SensorsSensorListSection>
        <SensorsSensorListSection reversed>
          <SensorsSensorListSectionItem>
            <IconContainer>
              <CortisolIcon className="cortisol" />
            </IconContainer>
            <TextWrapper>Skin conductance</TextWrapper>
          </SensorsSensorListSectionItem>
          <SensorsSensorListSectionItem>
            <IconContainer>
              <BarometerIcon className="barometer" />
            </IconContainer>
            <TextWrapper>Movement</TextWrapper>
          </SensorsSensorListSectionItem>
          <SensorsSensorListSectionItem>
            <IconContainer>
              <SPO2Icon className="spo2" />
            </IconContainer>
            <TextWrapper>
              Blood Oxygen
              <BadgeContainer>
                <BadgeTextWrapper>Coming Soon</BadgeTextWrapper>
              </BadgeContainer>
            </TextWrapper>
          </SensorsSensorListSectionItem>
        </SensorsSensorListSection>
      </ExtendedMotionDiv>
    </SensorsSensorListContainer>
  );
};

type SensorsSensorListProps = {
  scrolledDistance: MotionValue<number>;
};
