import * as React from 'react';
import { useTransform } from 'framer-motion';

import { useScrollDistanceForElementInView } from 'services/hooks';
import { easeOutSine } from 'services/animationHelpers';
import CloseDefault from 'vectors/internal/close_default.svg';

import {
  HomePhilipsContainer,
  ExtendedLogoNowatch,
  ExtendedLogoPhilips,
  ExtendedMotionDiv,
  MotionIconWrapper,
  LogoWrapper,
} from './styled';

export const HomePhilips: React.FC = () => {
  const [elementRef, scrolledDistance] = useScrollDistanceForElementInView();

  const scale = useTransform(scrolledDistance, [0.10, 0.42], [2.5, 1], {
    ease: easeOutSine,
  });
  const opacity = useTransform(scrolledDistance, [0.20, 0.42], [0, 1]);
  const y = useTransform(scrolledDistance, [0.10, 0.42], [-100, 0], {
    ease: easeOutSine,
  });
  const xLeftLogo = useTransform(scrolledDistance, [0.10, 0.42], [-500, 0], {
    ease: easeOutSine,
  });
  const xRightLogo = useTransform(scrolledDistance, [0.10, 0.42], [500, 0], {
    ease: easeOutSine,
  });

  return (
    <HomePhilipsContainer ref={elementRef}>
      <LogoWrapper>
        <ExtendedMotionDiv
          style={{
            scale,
            opacity,
            y,
            x: xLeftLogo,
          }}
        >
          <ExtendedLogoNowatch />
        </ExtendedMotionDiv>
        <MotionIconWrapper
          style={{
            opacity,
          }}
        >
          <CloseDefault />
        </MotionIconWrapper>
        <ExtendedMotionDiv
          style={{
            scale,
            opacity,
            y,
            x: xRightLogo,
          }}
        >
          <ExtendedLogoPhilips />
        </ExtendedMotionDiv>
      </LogoWrapper>
    </HomePhilipsContainer>
  );
};

