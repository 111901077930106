import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';
import { Text, StaticHeading } from 'common/typography';
import { Button } from 'common/interaction';

export const HomeAppContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.sand.lighter};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExtendedStaticHeading = styled(StaticHeading)`
  margin: 0;
  text-align: center;

  ${media.desktop`
    font-size: 6.5vw;
  `}
`;

export const ExtendedText = styled(Text)`
  max-width: 800px;
`;

export const ExtendedButton = styled(Button)`
  height: auto;
  padding: 14px 24px;
`;

export const HomeAppHeaderWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 10vh 10vw 12vh;

  ${media.desktop`
    padding: 100px 0 40px;
    margin-bottom: 100px;
  `}

  ${media.large`
    padding: 140px 0 0;
  `}
`;

export const AppBlocksContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  width: 140vw;
  height: 65vh;
  grid-gap: 2.4vw;

  ${media.desktop`
    grid-template-columns: repeat(5,1fr);
    width: 100%;
    height: 70vh;
  `}

  ${media.large`
    padding: 5vw;
    height: 85vh;
  `}
`;
