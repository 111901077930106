import * as React from 'react';

import { ContentBlocksContainer } from 'modules/contentBlocks';
import { IntroductionPress } from 'modules/introduction';

export const CampaignPressBlock: React.FC = () => {
  return (
    <ContentBlocksContainer>
      <IntroductionPress />
    </ContentBlocksContainer>
  );
};
