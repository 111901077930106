import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';
import { Text } from 'common/typography';

export const ExtendedTextSideviewTitle = styled(Text)`
  max-width: 90%;
  text-align: center;
  font-size: 4.5vw;
  margin-top: 40%;

  ${media.tablet`
    max-width: 50%;
    font-size: 3vw;
  `}

  ${media.desktop`
    font-size: 1.1vw;
    margin-top: 0;
    max-width: 30%;
    line-height: 110%;
  `}
`;

export const ExtendedTextSideviewDescription = styled(Text)`
  line-height: 100%;
  font-size: 4vw;
  max-width: 81%;
  text-align: center;
  margin-top: 4%;

  ${media.tablet`
    max-width: 50%;
    font-size: 2.5vw;
  `}

  ${media.desktop`
    padding: 0;
    max-width: 30%;
    font-size: 0.95vw;
    margin-top: 1%;
    line-height: 150%;
  `}
`;

export const ExtendedMotionDiv = styled(motion.div)`
  display: flex;
  align-items: center;

  ${media.desktop`
    position: absolute;
    z-index: 1;
  `}
`;

export const WatchSideviewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 40% 0 20% 0;

  ${media.desktop`
    margin-top: 0;
    padding: 30% 0 15% 0;

    ${ExtendedMotionDiv}:first-child {
      left: 0%;
    }

    ${ExtendedMotionDiv}:not(:first-child) {
      right: 0%;
    }
  `}
`;

export const ExtendedMotionDivText = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ExtendedVideo = styled.video<ExtendedVideoProps>`
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;

  ${({ $position }) => $position === 'left' && css`
    width: 140vw;
  `}

  ${({ $position }) => $position === 'right' && css`
    display: none;
  `}

  ${media.tablet<ExtendedVideoProps>`
    ${({ $position }) => $position === 'left' && css`
      width: 72.5vw;
    `}
  `}

  ${media.desktop<ExtendedVideoProps>`
    ${({ $position }) => $position === 'left' && css`
      width: 42vw;
    `}

    ${({ $position }) => $position === 'right' && css`
      display: block;
      width: 42vw;
    `}
  `}
`;

type ExtendedVideoProps = {
  $position: 'left' | 'right',
};
