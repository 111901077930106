import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';
import { Text, Heading } from 'common/typography';
import LogoNowatch from 'vectors/internal/logo_nowatch.svg';

export const IntroductionWatchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ExtendedMotionIntroductionTitleWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 90vh;

  ${media.tablet`
    top: 100vh;
  `}

  ${media.desktop`
    top: 100vh;
  `}

  ${media.large`
    top: 100vh;
  `}
`;

export const ExtendedMotionIntroductionLogoWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 150vh;

  ${media.desktop`
    top: 120vh;
  `}

  ${media.tablet`
    top: 150vh;
  `}

  ${media.large`
    top: 160vh;
  `}
`;

export const ExtendedLogo = styled(LogoNowatch)`
  width: 42vw;
  height: auto;
  flex-shrink: 0;

  ${media.tablet`
    width: 25vw;
  `}

  ${media.desktop`
    width: 18vw;
  `}

  ${media.large`
    width: 17vw;
  `}
`;

export const ExtendedText = styled(Text)`
  font-size: 4.3vw;
  margin-top: 3%;

  ${media.tablet`
    font-size: 2vw;
    margin-top: 1%;
  `}

  ${media.desktop`
    font-size: 1.8vw;
    margin-top: 1.5%;
  `}

  ${media.large`
    font-size: 1.3vw;
    margin-top: 1%;
  `}
`;

export const ExtendedVideo = styled.video`
  width: 100%;
  height: 100vh;
  display: block;
  object-fit: cover;
  pointer-events: none;
  user-select: none;

  ${media.desktop`
    height: calc(100vh - 62px);
    margin-top: 62px;
  `}

  ${media.large`
    height: calc(115vh - 62px);
  `}
`;

export const VideoContainer = styled.div`
  height: 100vh;
`;

export const ExtendedMotionVideoContainer = styled(motion.div)`
  height: 150vh;
`;

export const ExtendedHeading = styled(Heading)`
  text-align: center;
`;
