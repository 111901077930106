import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  TextAndImageBlockExtraFieldsContainer, ContentBlocksTitle, TextAndImageBlockExtraFieldsText,
  ContentBlocksSection,
} from 'modules/contentBlocks';
import { MembershipOptions } from 'modules/memberships';
import { Text } from 'common/typography';
import { BuyNowBlock } from 'common/layout';

import {
  HomePhilosophyContainer,
  ExtendedStaticHeading,
  HomePhilosophyTopContainer,
  ExtendedText,
  ExtendedHomeTextAndImageBlockImage,
} from './styled';

export const HomeMembership: React.FC = () => {
  return (
    <>
      <HomePhilosophyContainer>
        <HomePhilosophyTopContainer>
          <ExtendedStaticHeading as="h2" variant="display" $uppercase $isCentered>
            Pricing
          </ExtendedStaticHeading>
          <ExtendedText $isCentered $lineHeight={170}>
            NOWATCH is a device, app and membership. The combination will give you ongoing access to the latest biotechnologies, and mental wellbeing recommendations to suit your mind and body in real-time.
          </ExtendedText>
        </HomePhilosophyTopContainer>
        <ContentBlocksSection>
          <TextAndImageBlockExtraFieldsContainer isReversedMobile id="membership_why">
            <ExtendedHomeTextAndImageBlockImage alignLeft>
              <StaticImage
                src="../../../../assets/images/home_device.png"
                alt="NOWATCH Arm Holding Glass"
                placeholder="blurred"
                quality={90}
                objectFit="cover"
                className="home-text-and-image-block_image"
              />
            </ExtendedHomeTextAndImageBlockImage>
            <TextAndImageBlockExtraFieldsText>
              <ContentBlocksTitle uppercase>
                NOWATCH
              </ContentBlocksTitle>
              <Text $lineHeight={170}>
                Starting at &euro;427, including lifetime membership if you order now, the NOWATCH device can be configured with a selection of three different colours, eleven stones and discs and seventeen different straps. All our materials are ethically sourced, without any compromise on quality and sustainability.
              </Text>
              <BuyNowBlock />
            </TextAndImageBlockExtraFieldsText>
          </TextAndImageBlockExtraFieldsContainer>
        </ContentBlocksSection>
        <ContentBlocksSection>
          <TextAndImageBlockExtraFieldsContainer isReversed id="membership_how">
            <ExtendedHomeTextAndImageBlockImage>
              <StaticImage
                src="../../../../assets/images/woman_wearing_watch.png"
                alt="NOWATCH Woman Wearing Watch"
                placeholder="blurred"
                quality={90}
                objectFit="cover"
                className="home-text-and-image-block_image"
              />
            </ExtendedHomeTextAndImageBlockImage>
            <TextAndImageBlockExtraFieldsText>
              <ContentBlocksTitle uppercase>
                Membership
              </ContentBlocksTitle>
              <Text $lineHeight={170}>
                NOWATCH has everything you need to support the meaningful life you want, with real-time data-driven insights, full data privacy and security.<br /> We add new features, wellbeing recommendations, guided exercises and educational content every month.
              </Text>
              <Text $lineHeight={170}>
                Pick a monthly, yearly or three year membership.
              </Text>
              <Text $lineHeight={170} $color="red">
                Free lifetime membership if you order NOW.
              </Text>
            </TextAndImageBlockExtraFieldsText>
          </TextAndImageBlockExtraFieldsContainer>
        </ContentBlocksSection>
      </HomePhilosophyContainer>
    </>
  );
};
