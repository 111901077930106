import { Text } from 'common/typography';
import styled from 'styled-components';

import { media } from 'styles/utils';

export const IntroductionPressContainer = styled.div`
  width: 100%;
  margin: 100vw auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 40px;
  flex-wrap: wrap;

  ${media.desktop`
    margin: 60vw auto 0;
    max-width: 55vw;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: row;
    column-gap: 100px;
    row-gap: 32px;
  `}

  ${media.large`
    margin: 30vw auto 0;
  `}
`;

export const IntroductionPressLogos = styled.div`
  margin-top: 40px;

`;

export const IntroductionPressText = styled(IntroductionPressLogos)`
  margin-top: 0;
`;

export const DailyMailWrapper = styled.div`
  order: 5;
  width: 50vw;

  ${media.desktop`
    order: 3;
    width: 14vw;

    svg {
      margin-top: 20px;
    }

    p {
      margin-left: -20px;
      margin-right: -20px;
    }
  `}
`;

export const TheGuardianWrapper = styled.div`
  order: 1;
  width: 40vw;

  ${media.desktop`
    width: 10vw;

    p {
      margin-left: -30px;
      margin-right: -30px;
    }
  `}
`;

export const WiredWrapper = styled.div`
  order: 3;
  width: 50vw;

  ${media.desktop`
    order: 2;
    width: 12vw;

    svg {
      margin-top: 8px;
    }
  `}
`;

export const DailyMailTextWrapper = styled(DailyMailWrapper)`
  order: 6;

  ${media.desktop`
    order: 6;
  `}
`;

export const TheGuardianTextWrapper = styled(TheGuardianWrapper)`
  order: 2;

  ${media.desktop`
    order: 4;
  `}
`;

export const WiredTextWrapper = styled(WiredWrapper)`
  order: 4;

  ${media.desktop`
    order: 5;
  `}
`;

export const ExtendedText = styled(Text)`
  font-size: 14px;
  text-align: center;
  margin: 0;
  margin-top: -20px;

  ${media.desktop`
    font-size: 18px;
    margin-top: 0
  `}
`;

export const DesktopDivider = styled.div`
  display: none;

  ${media.desktop`
    display: block;
    width: 100%;
  `}
`;
