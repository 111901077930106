import styled from 'styled-components';
import { motion } from 'framer-motion';

import { media } from 'styles/utils';

export const CardContainer = styled(motion.div)`
  height: 100%;
  width: 100%;

  img {
    width: 100%;

    + img {
      margin-top: 2.4vw;
    }
  }

  &:last-child,
  &:first-child {
    display: none;
  }

  ${media.desktop`
    &:last-child,
    &:first-child {
      display: block;
    }
  `}
`;
